import { RootState } from '../rootReducer';
import { IUser } from './types';

export const selectUser = (state: RootState): IUser => state.user || undefined;
export const selectUsername = (state: RootState): string => state.user.username;
export const selectFirstName = (state: RootState): string => state.user.firstName;
export const selectLastName = (state: RootState): string => state.user.lastName;
export const selectUserTcCode = (state: RootState): string | undefined => state.user.tcCode;
export const selectTcTags = (state: RootState): string[] => state.user.tags;
export const selectUserGroups = (state: RootState): string[] => state.user.groups;
export const selectUserPermissions = (state: RootState): string[] => state.user.permissions;
