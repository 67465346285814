import { convertCustomerToSearchedCustomer } from 'utils/customer.utils';
import { unwrap } from 'utils/api.utils';
import { Log } from 'types/logs';
import {
  fetchTownAdapter,
  fetchTownCartRequestAdapter,
} from 'store/products';
import { clearDeliveryState } from 'store/delivery';
import { fetchCustomersNormalizer } from 'store/customers/normalizers';
import {
  CartRequestCartNormalizerPayload,
  CartRequestCustomerNormalizerPayload,
  CartRequestNormalizerPayload,
  CartRequestStateNormalizerReturnType,
  CreateCartStateReturnType,
  CreateCustomerStateReturnType,
  CreateProductsStateReturnType,
} from 'store/cartRequests/types';
import { ICartProductItem, ICustomerPostalCode } from 'store/cart/types';
import { clearCartState } from 'store/cart/reducer';
import { CustomersApiService } from 'services/customersServiceApi';

export const cartRequestStateNormalizer = async (
  payload: CartRequestNormalizerPayload,
): Promise<CartRequestStateNormalizerReturnType> => {
  const { cartRequestDetail } = payload;
  const { customerId } = cartRequestDetail;

  const customer = unwrap(await CustomersApiService.getCustomerById(customerId));
  const customerState = await createCustomerState({ ...payload, customer });
  const cart = await createCartState({ ...payload, customer });
  const products = await createProductsState(payload);

  return {
    customer: customerState,
    cart,
    products,
  };
};

const createCustomerState = async (
  payload: CartRequestCustomerNormalizerPayload,
): Promise<CreateCustomerStateReturnType> => {
  const { customer } = payload;

  const selectedSearchedCustomer = convertCustomerToSearchedCustomer(customer);
  const selectedCustomer = customer.id;

  const { customers } = fetchCustomersNormalizer([customer]);

  return {
    selectedSearchedCustomer,
    selectedCustomer,
    customers,
    whitelist: {
      isLoading: false,
      filter: null,
      total: Object.values(customers).length,
      page: 1,
    },
  };
};

type SavedCartState = {
  isSavedCart: boolean;
  cartId: string | null;
  products: Array<ICartProductItem>;
  logs: Log[];
};

const createCartState = async (payload: CartRequestCartNormalizerPayload): Promise<CreateCartStateReturnType> => {
  const { cartRequestDetail, dispatch, tcTags } = payload;
  const { id, cart: savedCart, delivery, atcCartNumber } = cartRequestDetail;

  dispatch(clearCartState());
  const savedCartState: SavedCartState = {
    isSavedCart: false,
    cartId: null,
    products: [],
    logs: [],
  };

  return {
    ...savedCartState,
    cartRequestId: id,
    atcCartNumber,
  };
};

const createProductsState = async (
  payload: CartRequestNormalizerPayload,
): Promise<CreateProductsStateReturnType | undefined> => {
  const { cartRequestDetail, dispatch } = payload;
  if (!cartRequestDetail.delivery) {
    return;
  }
  const { delivery } = cartRequestDetail;

  const { townId } = delivery;

  dispatch(clearDeliveryState());
  const postalCodes: ICustomerPostalCode = await fetchTownAdapter(fetchTownCartRequestAdapter({ townId }));

  return {
    postalCodes,
  };
};
