import { sendMessage } from 'store/messages';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ChangeEvent, FC, useEffect, useMemo, useState } from 'react';
import { Text } from 'components/shared';
import { Input } from 'antd';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import Button from '../Button';
import { IQuantityProps } from './Quantity.types';
import styles from './Quantity.module.scss';

const Quantity: FC<IQuantityProps> = (props: IQuantityProps) => {
  const {
    subTitle,
    onChange,
    onDecrease,
    onIncrease,
    value,
    minimum = 0,
    maximum = 0,
    disabled,
    onCLick,
    notifyMinMax = true,
    ignoreStock = false,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [internalValue, setInternalValue] = useState<string | number>(String(value) || String(minimum));

  useEffect(() => {
    if (value >= minimum && internalValue !== '') setInternalValue(value);
  }, [internalValue, value, minimum]);

  const handleQuantityChange = (e: ChangeEvent<HTMLInputElement>, type = 'blur'): void => {
    let val = !isNaN(parseInt(e.target.value)) ? parseInt(e.target.value) : minimum;

    if (e.target.value.trim() === '') {
      setInternalValue('');
      onChange(val);
      if (type === 'change') return;
    }

    if (val < minimum) {
      val = minimum;

      if (notifyMinMax) {
        dispatch(
          sendMessage({
            message: t('common.quantitySmallerThanMinimum'),
            type: 'warning',
          }),
        );
      }
    }
    if (!ignoreStock && maximum > 0 && val > maximum) {
      val = maximum;

      if (notifyMinMax) {
        dispatch(
          sendMessage({
            message: t('common.quantityBigerThanMaximum'),
            type: 'warning',
          }),
        );
      }
    }

    setInternalValue(val);
    onChange(val);
  };

  return (
    <div className={styles.container}>
      <div>
        {!!(value && subTitle && !disabled) && (
          <Text className={styles.subtitle} type="title">
            {subTitle}
          </Text>
        )}
      </div>
      <div className={styles.group}>
        <Button
          disabled={disabled || value <= minimum}
          onClick={onDecrease}
          className={`${styles.group__button}`}
          icon={<MinusOutlined />}
        />

        <Input
          type="number"
          onClick={onCLick}
          disabled={disabled}
          className={styles.group__input}
          min={minimum}
          max={maximum}
          value={internalValue}
          onChange={(e) => handleQuantityChange(e, 'change')}
          onBlur={(e) => handleQuantityChange(e)}
        />

        <Button
          disabled={disabled || (!ignoreStock && value >= maximum)}
          onClick={onIncrease}
          className={`${styles.group__button}`}
          icon={<PlusOutlined />}
        />
      </div>
    </div>
  );
};

export default Quantity;
