import { FC } from 'react';
import Price from '../Price/Price';
import styles from './ProductPrice.module.scss';

type SimplePriceProps =  {
  unitPrice: number,
  conditioning?: string,
  conditioningQuantity?: number,
  unit?: string,
}

export const SimplePrice: FC<SimplePriceProps> = (props: SimplePriceProps) => {
  const {
    unitPrice,
    conditioning,
    conditioningQuantity,
    unit,
  } = props;

  return (
    <div className={`${styles.block}`}>
        <>
            <Price unitPrice={unitPrice} unit={unit} type={'EUR'} />
            {(!!conditioning && !!conditioningQuantity && conditioningQuantity !== 1) &&
              <Price
                unitPrice={conditioningQuantity * unitPrice}
                unit={conditioning}
                className={styles.price_conditioning}
              />
            }
        </>
    </div>
  );
};
