import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { FC, useEffect, useMemo, useState } from 'react';
import { Input, Radio, RadioChangeEvent, Tooltip } from 'antd';
import { InfoCircleFilled } from '@ant-design/icons';
import { Text } from '../index';
import { toFixedTrunc } from '../../../utils';
import { sendMessage } from '../../../store/messages';
import { updateProductFields } from '../../../store/cart';
import { DiscountError, DiscountProps, DiscountSource, DiscountType } from './Discount.types';
import styles from './Discount.module.scss';

const Discount: FC<DiscountProps> = (props: DiscountProps) => {
  const dispatch = useDispatch();
  const {
    productIndex,
    price,
    discountValue: productDiscountValue,
    discountType: productDiscountType,
    discountError,
    discountSource: productDiscountSource,
    enforcedPrice,
  } = props;
  const { initialPrice } = price;
  const [discount, setDiscount] = useState({
    discountValue: ((productDiscountValue ?? 0) / 100).toString() ?? '0',
    discountType: productDiscountType ?? null,
    discountSource: productDiscountSource ?? null,
  });

  const [originalOfferPrice] = useState(initialPrice ?? 0);
  const { t } = useTranslation();

  /**
   * Set discount value, type and source when they are changed in the store
   */
  useEffect(() => {
    if (productDiscountValue && productDiscountType && productDiscountSource) {
      setDiscount((prevState) => ({
        ...prevState,
        discountValue: ((productDiscountValue ?? 0) / 100).toString(),
        discountType: productDiscountType,
        discountSource: productDiscountSource,
      }));
    }
  }, [productDiscountValue, productDiscountType, productDiscountSource]);


  const changeDiscountType = (e: RadioChangeEvent) => {
    let changedDiscountType = e.target.value;
    /**
     * if the discount type is One shot we have to update it to Amount per unit because we dont have a special Radio
     * Button for One shot
     */
    if (e.target.value === DiscountType.AMOUNT_ONE_SHOT) {
      changedDiscountType = DiscountType.AMOUNT_PER_UNIT;
    }
    setDiscount((prevState) => ({
      ...prevState,
      discountType: changedDiscountType,
      discountSource: DiscountSource.MANUAL,
    }));

    if (discount.discountValue === '0' || discount.discountValue === '') {
      return;
    }

    let newValue = 0;
    if (changedDiscountType === DiscountType.PERCENTAGE) {
      newValue = (1 - (originalOfferPrice - parseFloat(discount.discountValue) * 100) / originalOfferPrice) * 100;
    }
    /**
     * For one shot we calculate price exactly like for amount per unit
     */
    if (changedDiscountType === DiscountType.AMOUNT_PER_UNIT) {
      newValue = ((originalOfferPrice / 100) * parseFloat(discount.discountValue)) / 100;
    }
    setDiscount((prevState) => ({
      ...prevState,
      discountValue: toFixedTrunc(newValue.toFixed(2)),
    }));
  };

  const showDiscountError = useMemo(() => {
    if (typeof discountError === 'undefined' || discountError === DiscountError.FALSE) {
      return false;
    }
    return discountError;
  }, [discountError]);

  const changeDiscountValue = (value: string) => {
    if (value.trim() === '') {
      setDiscount((prevState) => ({
        ...prevState,
        discountValue: value,
      }));

      return;
    }

    const newDiscountValue = toFixedTrunc(value !== '' ? value : '0');

    setDiscount((prevState) => ({
      ...prevState,
      discountValue: newDiscountValue,
      discountSource: DiscountSource.MANUAL,
    }));
  };

  /**
   * Update in store values based on discount object
   */
  useEffect(() => {
    /**
     * If discount comes from OFFER we dont need to update it
     * It was already updated from other components
     */
    if (discount.discountSource === DiscountSource.OFFER) return;
    /**
     * If discount value is empty we set it to 0
     */
    if (discount.discountValue === '') {
      dispatch(
        updateProductFields({
          identifier: productIndex,
          values: {
            discountValue: 0,
            discountError: undefined,
            discountSource: DiscountSource.MANUAL,
          },
        }),
      );
      return;
    }

    let error = false;
    /**
     * Check if there is an error in discount
     */
    switch (discount.discountType) {
      case DiscountType.PERCENTAGE:
        if (parseFloat(discount.discountValue) > 100 || parseFloat(discount.discountValue) < 0) {
          error = true;
          dispatch(sendMessage({ message: t('errors.invalidDiscountPercentageValue'), type: 'error' }));
        }
        break;
      case DiscountType.AMOUNT_ONE_SHOT:
      case DiscountType.AMOUNT_PER_UNIT:
        if (parseFloat(discount.discountValue) * 100 > originalOfferPrice || parseFloat(discount.discountValue) < 0) {
          error = true;
          dispatch(sendMessage({ message: t('errors.invalidDiscountAmountValue'), type: 'error' }));
        }
        break;
    }

    if (error) return;

    /**
     * If there is no error we update the store with manually entered data
     */
 if ((productDiscountType !== discount.discountType || productDiscountValue !== parseFloat(discount.discountValue))) {
      dispatch(
        updateProductFields({
          identifier: productIndex,
          values: {
            discountType: discount.discountType === DiscountType.AMOUNT_ONE_SHOT ? DiscountType.AMOUNT_PER_UNIT : discount.discountType,
            discountValue: parseFloat(discount.discountValue) * 100,
            discountSource: DiscountSource.MANUAL,
            enforcedPrice: false,
          },
        }),
      );
    }
  }, [discount]);

  return (
    <div className={styles.discountGroup}>
      <Text className={styles.text}>{t('common.discountApplied')}</Text>
      {showDiscountError && (
        <Tooltip title={t('errors.discountValueTooHigh')}>
          <InfoCircleFilled style={{ color: 'red', fontSize: '20px', marginRight: '5px' }} />
        </Tooltip>
      )}
      <Input
        type="string"
        className={styles.input}
        defaultValue={discount.discountValue}
        status={showDiscountError ? 'error' : undefined}
        value={discount.discountValue}
        onChange={(e) => changeDiscountValue(e.target.value)}
      />
      <Radio.Group
        defaultValue={DiscountType.AMOUNT_PER_UNIT}
        value={
          discount.discountType === DiscountType.AMOUNT_ONE_SHOT ? DiscountType.AMOUNT_PER_UNIT : discount.discountType
        }
        buttonStyle="solid"
        onChange={changeDiscountType}
      >
        <Radio.Button value={DiscountType.PERCENTAGE}>%</Radio.Button>
        <Radio.Button value={DiscountType.AMOUNT_PER_UNIT}>€</Radio.Button>
      </Radio.Group>
    </div>
  );
};
export default Discount;
