export const priceParser = (price: number, divider = 100, withCurrency = true): string => {
  const currency = withCurrency
    ? {
        style: 'currency',
        currency: 'EUR',
      }
    : {};
  return new Intl.NumberFormat('fr-FR', currency).format(price / divider);
};

export const percentageParser = (percentage: number): string => {
  return Number(percentage / 100).toLocaleString(undefined, {
    style: 'percent',
    minimumFractionDigits: 2,
  });
};

export const priceFormatting = (price: number) => {
  const splitPrice = (price / 100).toFixed(2).split('.');
  return {
    price: new Intl.NumberFormat('fr-FR').format(Number(splitPrice[0])),
    decimals: splitPrice[1],
  };
};
