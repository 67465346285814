import { INormalizedList } from 'types';
import { IProductsOffer, IProductTags } from 'store/products/types';

export type IProductOfferShippingMethod = {
  id: number;
  label: string;
  minDelay?: number;
  maxDelay?: number;
  type: string;
};

export type IProductShippingMethod = {
  offerId: number;
  methods: Array<IProductOfferShippingMethod>;
};

export type IProductTaxes = {
  cvo?: number;
  ecoContribution?: number;
  rpd?: number;
  vat: number;
};

export type MachineModel = {
  type: string;
  brandId: number;
  brand: string;
  model?: string;
};

export type Option = {
  [k: string]: string;
};

export type IAlgoliaEquivalentProduct = {
  productId: number;
  productName: string;
  bu: string;
  amm: string;
  publicComment?: string;
  privateComment?: string;
};

export type IAlgoliaSubstitutionProduct = IAlgoliaEquivalentProduct;

export type IProductReplacement = {
  type: ProductReplacement;
  name: string;
  productId: number;
  variantId: number;
};

export type IAlgoliaProduct = {
  amm?: string;
  bu: string;
  brand: string;
  brandId: number;
  conditioning?: string;
  conditioningQuantity: number;
  ean?: string;
  keywords?: string;
  machineModels: Array<MachineModel>;
  offerShippingMethods?: Array<IAlgoliaOfferShippingMethod>;
  equivalentProducts?: Array<IAlgoliaEquivalentProduct>;
  substitutionProducts?: Array<IAlgoliaSubstitutionProduct>;
  name: string;
  objectID: string;
  options: Option;
  originReferences?: Array<string>;
  productId: number;
  productType: string;
  shippingMethods: Array<IProductShippingMethod>;
  sku: string;
  unitSingular: string;
  unitPlural: string;
  variantId: number;
  isTownRequired: boolean;
  weight: number;
  taxes: {
    FR?: IProductTaxes;
  };
  sortingIndex: number;
  flags?: string[];
  tags?: string[];
  productTags:IProductTags[];
  meta?: {
    business_note: string;
  } | null;
};

export type IAlgoliaProductResponse = Omit<IAlgoliaProduct, 'shippingMethods'> & {
  shippingMethods?: Array<IProductShippingMethod>;
};

export type IAlgoliaOfferShippingMethod = IAlgoliaOfferShippingMethodShort | IAlgoliaOfferShippingMethodLong;

export type IAlgoliaOfferShippingMethodShort = {
  o_id: number;
  sm_ids: Array<number>;
};

export type IAlgoliaOfferShippingMethodLong = {
  offer_id: number;
  shipping_method_ids: Array<number>;
};

// eslint-disable-next-line no-shadow
export enum ProductReplacement {
  SUBSTITUTE = 'SUBSTITUTE',
  EQUIVALENT = 'EQUIVALENT',
}

export type PostUploadFileResponse = {
  filename: string;
  rows: Array<MultiSearchHeader>;
};

export type MultiSearchHeader = {
  query: string;
  qty: number;
  unit: string;
};

export type IProductTypeDefault = 'default';
export type IProductTypeMulti = 'multi';
export type IProductTypeCustomerQuotation = 'customer-quotation';
export type IProductTypeHistoricalSearch = 'historical-product-search'
export type IProductType = IProductTypeDefault | IProductTypeMulti | IProductTypeCustomerQuotation | IProductTypeHistoricalSearch;

export type IHistoricalPrice = {
  id: number;
  customerIdentifier: string;
  customerName: string;
  label: string;
  sku: string;
  unitPrice: number;
  quantity: number;
  volume: number;
  unitLabel: string;
  cartId: null | string;
  atcCode: string;
  atcName: string;
  fiscalYear: number;
  createdAt: string;
  updatedAt: string;
  invoiceNumber: string | null;
  purchaseOrderNumber: string | null;
};

export type ParsedProductsForTable = {
  products: INormalizedList<IAlgoliaProduct>;
  offers: IProductsOffer;
};

export type GetBestOffersParams = {
  products: Array<number>;
  postcode?: string;
  tags?: Array<string>;
  allOffers?: boolean;
  clubMemberExpDate?: Nullable<Date>;
  excludeNoStockOffers?: boolean;
};

export type Unit = {
  id: number;
  locale: string;
  singular: string;
  plural: string;
};
