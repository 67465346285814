import { FC } from 'react';
import styles from './FlagList.module.scss';

type IFlagListProps = {
  className?: string;
  flags?: Array<string>;
};

const FlagList: FC<IFlagListProps> = (props: IFlagListProps) => {
  const { className, flags } = props;
  return (
    <>
      <div className={`${styles.list} ${className}`}>
        {flags?.map((flag, index) => (
          <span className={styles.flag} key={flag + '-' + index}>
          {flag}
        </span>
        ))}
      </div>
    </>

  );
};

export default FlagList;
