import { AsyncThunk, createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { handleCatchError } from '../../utils';
import { ErrorType } from '../../types';
import { HistoricalProductSearchService } from '../../services/historicalProductSearch.service';
import { HistoricalProductSearch, HistoricalProductSearchFilter, IHistoricalProductSearchState } from './type';
export const HISTORICAL_PRODUCT_SEARCH_REDUCER_NAME = 'historical_product_search';

const initialState: IHistoricalProductSearchState = {
  isLoading: false,
  search: {
    results: [],
  }
};

export const fetchHistoricalProductSearch: AsyncThunk<HistoricalProductSearch[], { filter: HistoricalProductSearchFilter }, { rejectValue: ErrorType }> = createAsyncThunk(
  `${HISTORICAL_PRODUCT_SEARCH_REDUCER_NAME}/search`,
  async ({filter}, { rejectWithValue }) => {
    try {
      const result =  await HistoricalProductSearchService.search(filter);

      return result.data;
    } catch (err) {
      return handleCatchError(err, rejectWithValue);
    }
  },
);


const historicalProductSearchSlice = createSlice({
  name : HISTORICAL_PRODUCT_SEARCH_REDUCER_NAME,
  initialState,
  reducers: {
    clearHistoricalProductSearchState() {
      return { ...initialState };
    },
  },
  extraReducers: (builder) => {

    builder.addCase(fetchHistoricalProductSearch.fulfilled, (state: IHistoricalProductSearchState, { payload } : PayloadAction<HistoricalProductSearch[]>) => {
        state.search.results = payload;
    });

  }
});

export const {
  clearHistoricalProductSearchState,
} = historicalProductSearchSlice.actions;
export default historicalProductSearchSlice.reducer;
