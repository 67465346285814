import { createTranslation } from 'utils/translation.utils';
import { isEmpty } from 'utils/object.utils';
import { createError } from 'utils/error.utils';
import {
  SavedCartSateProductItem,
  SavedCartSavedCustomersGroupState,
  SavedCartSavedCustomerSingleState,
  SavedCartSavedCustomerState,
  SavedCartSavedState,
  SavedCartStateProductItemKey,
} from 'store/savedCart/types';
import { RootState } from 'store/rootReducer';
import { ICartProductItem, SupplierPriceType } from 'store/cart/types';
import moment from 'moment';
import { Supplier } from '../types/suppliers';
import { ActionType } from '../types/logs';
import { INormalizedList } from '../types';
import { ICategorizedShippingItems } from '../store/delivery/types';
import { DEFAULT_DAYS, DEFAULT_HOURS } from '../constants/routes';
import { selectCustomer } from './pdf/utils';

const trans = createTranslation('SavedCart');

export const createSavedCartState = (
  state: RootState,
  { stepNumber, allowEmptySave }: { stepNumber: number; allowEmptySave?: boolean },
): SavedCartSavedState => {
  const customers = createCustomersState(state);
  const town = createTownState(state);
  const cart = createCartState(state, { allowEmptySave });
  const delivery = createDeliveryState(state);
  const pendingLogs = createPendingLogsState(state);

  const expiredAt = state.savedCart.restore.expiredAt;
  const updatedAt = moment().utc(false).format();
  const expirationDays = state.savedCart.restore.expirationDays ?? DEFAULT_DAYS;
  const expirationHours = state.savedCart.restore.expirationHours ?? DEFAULT_HOURS;

  return {
    customers,
    town,
    cart,
    delivery,
    stepNumber,
    updatedAt,
    expiredAt,
    expirationDays,
    expirationHours,
    pendingLogs,
  };
};

const createTownState = (state: RootState): SavedCartSavedState['town'] => {
  const {
    products: { postalCodes: town },
  } = state;

  if (!town) {
    throw createError(trans('You must select the postal code in order to save the cart.'));
  }

  const { id, town: name, postcode, countryCode } = town;

  return {
    id,
    town: name,
    postcode,
    countryCode,
  };
};

const createSingleCustomersState = (state: RootState): SavedCartSavedCustomerSingleState => {
  const { customers } = state;
  const { selectedSearchedCustomer, customers: customerList } = customers;

  if (!selectedSearchedCustomer) {
    throw createError(trans('Invalid Action. There are no customers selected.'));
  }

  const { id, userId, firstName, lastName, customerIdentifier, company, email } = selectedSearchedCustomer;

  return {
    isGroup: false,
    selectedCustomer: id,
    selectedSearchedCustomer: {
      id,
      userId,
      firstName,
      lastName,
      customerIdentifier,
      company,
      email,
    },
    billingCustomer: selectCustomer(customerList, id),
  };
};

const createGroupCustomersState = (state: RootState): SavedCartSavedCustomersGroupState => {
  const { customers } = state;
  const { selectedGroupId, selectedGroupCustomers, groupTags } = customers;

  if (
    !selectedGroupId ||
    !selectedGroupCustomers ||
    (selectedGroupCustomers && Object.keys(selectedGroupCustomers).length === 0)
  ) {
    throw createError(trans('Invalid Action. There is no selected group.'));
  }

  const groupLead = Object.values(selectedGroupCustomers).find((c) => c.userId === c.leaderId || c.isCustomerLead);

  if (!groupLead) {
    throw createError(trans('Invalid Action. There is no selected group.'));
  }

  const { id, userId, customerIdentifier, company, email } = groupLead;

  return {
    isGroup: true,
    selectedGroupId,
    selectedSearchedGroupCustomer: {
      id,
      userId,
      customerIdentifier,
      company,
      email,
    },
    groupTags: groupTags[selectedGroupId] || [],
    selectedGroupCustomers: Object.values(selectedGroupCustomers),
  };
};

const createCustomersState = (state: RootState): SavedCartSavedState['customers'] => {
  const {
    customers: { isGroup },
  } = state;

  if (isGroup) {
    return createGroupCustomersState(state);
  }

  return createSingleCustomersState(state);
};

const createCartState = (state: RootState, options: { allowEmptySave?: boolean }): SavedCartSavedState['cart'] => {
  const {
    cart: {
      atcCartNumber,
      isEco,
      products,
      comment,
      deliveryComment,
      invoiceComment,
      cartRequestId,
      sendQuoteByEmail,
      groups,
      logs,
    },
    products: { suppliers },
  } = state;

  const { allowEmptySave } = options;

  if (isEmpty(products) && !allowEmptySave) {
    throw createError(trans('You must have at least one product in cart in order to save it.'));
  }

  const getBuyingPriceFields = (initialPrice?: number, supplierPrice?: SupplierPriceType | null) => {
    if (supplierPrice) {
      const { supplierId: overrideSupplierId, price } = supplierPrice;
      const supplier = (suppliers as Supplier[]).find((item) => item.id === overrideSupplierId);
      return {
        supplier_name: supplier?.name || '',
        ...(initialPrice && {
          buying_price: initialPrice,
        }),
        buying_price_overrided: price,
      };
    }

    return {
      ...(initialPrice && {
        buying_price: initialPrice,
      }),
    };
  };

  return {
    atcCartNumber,
    cartRequestId,
    isEco,
    sendQuoteByEmail,
    groups,
    products: Object.values(products).map((cartProduct: ICartProductItem): SavedCartSateProductItem => {
      return {
        ...sanitizeCartProductItem(cartProduct),
        ...getBuyingPriceFields(cartProduct?.price?.supplierUnitPrice, cartProduct?.supplierPrice),
      };
    }),
    comment,
    deliveryComment,
    invoiceComment,
    logs,
    page: state.settings.PRODUCT_PAGE,
  };
};

const createDeliveryState = (state: RootState): SavedCartSavedState['delivery'] => {
  const {
    delivery: { shippingAddress, categoryPointList, shippingItems, shippingFees, shippingCategories, shippingDetails },
  } = state;

  if (!shippingAddress) {
    return;
  }

  return {
    shippingFees,
    shippingAddress,
    shippingItems: shippingItems.map((item) => {
      const { offerId, shippingMethodId } = item;

      return {
        offerId,
        shippingMethodId,
      };
    }),
    categoryPointList: Object.entries(categoryPointList).map((item) => ({
      shippingMethodId: Number(item[0]),
      pointListId: String(item[1]),
    })),
    shippingCategories: Object.entries(shippingCategories).reduce<INormalizedList<ICategorizedShippingItems>>(
      (acc, [key, category]) => {
        const pointList = category.isPointList
          ? category.pointList?.filter((list) => list.id === categoryPointList[category.shippingMethodId]) ?? null
          : null;

        acc[key] = {
          ...category,
          pointList: pointList && pointList.length < 1 ? null : pointList,
        };

        return acc;
      },
      {},
    ),
    shippingDetails,
  };
};

const createPendingLogsState = (state: RootState): SavedCartSavedState['pendingLogs'] => {
  return state.cart.pendingLogs[ActionType.CREATE]
    .concat(state.cart.pendingLogs[ActionType.DELETE])
    .concat(Object.values(state.cart.pendingLogs[ActionType.UPDATE]).flatMap((idMap) => Object.values(idMap)));
};

export const sanitizeCartProductItem = (cartProduct: ICartProductItem): SavedCartSateProductItem => {
  const keys: SavedCartStateProductItemKey[] = [
    'id',
    'bu',
    'amm',
    'cartRequestItemId',
    'cartProductId',
    'productId',
    'offerId',
    'offerType',
    'variantId',
    'quantity',
    'name',
    'label',
    'conditioning',
    'conditioningQuantity',
    'sku',
    'price',
    'offerPrice',
    'shippingMethods',
    'unit',
    'taxes',
    'quantityIncrement',
    'minimumOrderable',
    'priceTotalWithTaxes',
    'totalPrice',
    'cvo',
    'rpd',
    'stock',
    'weight',
    'days',
    'isTownRequired',
    'isTruck',
    'startDate',
    'endDate',
    'supplierId',
    'departureId',
    'initialPrice',
    'productOptions',
    'availableSelectedQuantity',
    'availableQuantity',
    'customerProductInfo',
    'truckSplitInfo',
    'enabled',
    'noStock',
    'replacement',
    'publicComment',
    'discountValue',
    'discountType',
    'discountSource',
    'supplierPrice',
    'removable',
    'qtyEditable',
    'enforcedPrice',
    'attachments',
    'isPlainProduct',
    'supplierReference',
    'overriddenDeliveryStartDate',
    'overriddenDeliveryEndDate',
    'deliveryOverridden',
    'metadata',
    'ignoreStock',
    'flags',
    'tags',
    'productTags',
  ];

  const saveCartProduct: Partial<SavedCartSateProductItem> = {};
  for (const key of keys) {
    if (Object.prototype.hasOwnProperty.call(cartProduct, key)) {
      saveCartProduct[key] = cartProduct[key] as never;
    }
  }

  return saveCartProduct as SavedCartSateProductItem;
};

export const isSavedCartSingleCustomer = (
  customersState: SavedCartSavedCustomerState,
): customersState is SavedCartSavedCustomerSingleState => {
  return !customersState.isGroup;
};

export const isSavedCartGroupCustomer = (
  customersState: SavedCartSavedCustomerState,
): customersState is SavedCartSavedCustomersGroupState => {
  return customersState.isGroup;
};
