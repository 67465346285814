import { SavedCartStatusEnum } from 'store/savedCart/types';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { FC, useEffect, useMemo, useState } from 'react';
import { camelCase } from 'lodash';
import env from 'config';
import { Text } from 'components/shared';
import { Alert, Button, Col, Flex, Row, Table, Tooltip } from 'antd';
import { PlusOutlined, ShoppingCartOutlined } from '@ant-design/icons';
import { DiscountSource, typeToDiscountMap } from '../../shared/Discount/Discount.types';
import { priceParser } from '../../../utils';
import { IOfferPrice } from '../../../types/offer';
import { useAppSelector } from '../../../store/store';
import { selectPostalCode, setAddProductModalVisible } from '../../../store/products';
import { selectTags, selectTcTags } from '../../../store/customers';
import { ProductNotificationsType } from '../../../store/cart/types';
import {
  clearProductNotifications,
  selectAtcCartNumber,
  selectCartOffers,
  selectCartProducts,
  selectCartStatus,
  updateCartOffers,
  updateOfferProductPrice,
  updateProductNotifications,
} from '../../../store/cart';
import ExpandAllProducts from './components/ExpandAllProducts';
import DeleteAllProducts from './components/DeleteAllProducts';
import styles from './CartHeader.module.scss';

export const CART_HEADER_ID = 'cart-header-id';

type OfferValidationMessage = {
  message: string;
  type: string;
  productName: string;
  sku: string;
  offerId: number;
};

enum OfferValidationType {
  OFFER_PRICE_UPDATED = 'OFFER_PRICE_UPDATED',
  NO_OFFER_FOUND = 'NO_OFFER_FOUND',
  OFFER_NO_STOCK = 'OFFER_NO_STOCK',
  OFFER_MARGIN_ISSUE = 'OFFER_MARGIN_ISSUE',
}

const CartHeader: FC = () => {
  const { t } = useTranslation();
  const atcCartNumber = useAppSelector(selectAtcCartNumber);
  const status = useAppSelector(selectCartStatus);
  const products = useAppSelector(selectCartProducts);
  const postalCode = useAppSelector(selectPostalCode);
  const tags = useAppSelector(selectTags);
  const tcTags = useAppSelector(selectTcTags);
  const offers = useAppSelector(selectCartOffers);
  const [offerErrors, setOfferErrors] = useState<OfferValidationMessage[]>([]);
  const dispatch = useDispatch();
  const title = useMemo(
    () => (atcCartNumber ? `${t('saved_cart.atcCartNumber')} ${atcCartNumber}` : t('common.cart')),
    [atcCartNumber, t],
  );

  useEffect(()=> {
    dispatch(updateCartOffers({
      postCode: postalCode?.postcode as string,
      tags: [...(tags ?? []), ...tcTags],
    }));

  }, []);

  useEffect(() => {
    checkOfferValidity(offers);
  }, [products, offers]);

  useEffect(() => {
    (async ()=> {
       const notifications: {[key:number]:ProductNotificationsType} = {};
       dispatch(clearProductNotifications());
       offerErrors.forEach((error) => {

         if (notifications[error.offerId]) {
           if (notifications[error.offerId].className !== 'red' && error.type === OfferValidationType.NO_OFFER_FOUND) {
             notifications[error.offerId].className = 'red';
           }
           notifications[error.offerId].message = [...(notifications[error.offerId].message.split(',') ?? []), t('cart.' + error.type)].join(', ');
         } else {
           notifications[error.offerId] = {
             className: (error.type === OfferValidationType.NO_OFFER_FOUND ?  'red' : 'orange'),
             message: t('cart.' + error.type)
           };
         }
       })
      dispatch(updateProductNotifications({notifications}));
    })();
  }, [offerErrors]);

  const [loading, setLoading] = useState(false);
  const updateNewOfferPrice = () => {
    setLoading(true);

    offerErrors.forEach((error) => {
      if (error.type ===  OfferValidationType.OFFER_PRICE_UPDATED) {
        dispatch(updateOfferProductPrice({
          id: error.offerId,
          price: offers[error.offerId].RawPrice,
          discountValue : offers[error.offerId].DiscountValue,
          discountSource:  DiscountSource.OFFER,
          discountType : typeToDiscountMap[offers[error.offerId].DiscountType],
        }));
      }
    });
    setLoading(false);

  };

  const columns = [
    {
      title: 'Code',
      width: '5%',
      dataIndex: 'productCode',
    },
    {
      title: 'Offre',
      width: '5%',
      dataIndex: 'offerId',
    },
    {
      title: 'Nom produit',
      width: '30%',
      dataIndex: 'productName',
    },
    {
      title: 'Evénement',
      width: '10%',
      dataIndex: 'type',
    },
    {
      title: 'Description',
      width: '30%',
      dataIndex: 'description',
    },
  ];
  const dataSource = offerErrors.map((error, index) => {
    return {
      key: index,
      productCode: error.sku,
      offerId: error.offerId,
      productName: error.productName,
      type: t('cart.' + error.type),
      description: error.message,
    };
  });

  const nbOfferPriceChanged = offerErrors.filter((error) => error.type === OfferValidationType.OFFER_PRICE_UPDATED).length;

  const openAddProductModal = () => {
    dispatch(setAddProductModalVisible(true));
  };

  const checkOfferValidity = async (offers: { [key: string]: IOfferPrice }) => {
    const errorList: OfferValidationMessage[] = [];
    await Promise.all(products.map(async (product) => {
      const offer: IOfferPrice = offers[product.offerId] ?? null;

      // margin issue
      const buying_price =  product.supplierPrice?.price ?? (product.price.supplierUnitPrice ?? null);
      if ((buying_price && product.offerPrice < buying_price) || product.offerPrice === 0) {
        errorList.push({
          message: t('cart.checkOfferErrorMessageMargin', {
            offerId: product.offerId,
          }),
          type: OfferValidationType.OFFER_MARGIN_ISSUE,
          productName: product.name,
          sku: product.sku,
          offerId: product.offerId,
        });
      }

      if (offer?.Offer?.Stock < (product.quantity * product.quantityIncrement) && !product.ignoreStock) {
        errorList.push({
          message: t('cart.checkOfferErrorMessageNoStock', {
            requiredStock: product.quantity * product.quantityIncrement,
            availableStock: offer?.Offer?.Stock,
          }),
          type: OfferValidationType.OFFER_NO_STOCK,
          productName: product.name,
          sku: product.sku,
          offerId: product.offerId,
        });
      }

      // don't check error for new item in the cart
      if (!product.id) {
        return;
      }

      if (!offers[product.offerId]) {
        errorList.push({
          message: t('cart.checkOfferErrorMessageNotFound', {
            offerId: product.offerId,
          }),
          type: OfferValidationType.NO_OFFER_FOUND,
          productName: product.name,
          sku: product.sku,
          offerId: product.offerId,
        });

        return;
      }


      let currentOfferPrice = product.offerPrice;
      let newOfferPrice = offer.DiscountedPrice;

      if ((product.discountValue ?? 0) > 0 && product.discountSource === DiscountSource.MANUAL) {
        currentOfferPrice = product.offerUnitPrice ?? product.price.initialPrice;
        newOfferPrice = offer.RawPrice;
      }
      else if ((product.discountValue ?? 0) > 0 && product.discountSource === DiscountSource.OFFER) {
        currentOfferPrice = product.offerUnitPrice ?? product.price.initialPrice;
        newOfferPrice = offer.DiscountedPrice;
      }
      else if (product.enforcedPrice) {
        currentOfferPrice = product.offerUnitPrice ?? product.price.initialPrice;
        newOfferPrice = offer.DiscountedPrice;
      }

      const hasOfferInitialPriceChanged = currentOfferPrice !== newOfferPrice;
      if (hasOfferInitialPriceChanged && !product.enforcedPrice) {
        errorList.push({
          message: t('cart.checkOfferErrorMessagePriceUpdated', {
            offerId: product.offerId,
            currentOfferPrice: priceParser(currentOfferPrice),
            newOfferPrice: priceParser(newOfferPrice),
          }),
          type: OfferValidationType.OFFER_PRICE_UPDATED,
          productName: product.name,
          sku: product.sku,
          offerId: product.offerId,
        });
      }

    }));
    setOfferErrors(errorList);

    return;
  };

  return (
    <>
      {offerErrors.length > 0 && (
        <>
          <Row>
            <Col span={24}>
              <Flex gap="middle" vertical>
                <br/>
                  <Alert
                  message={<strong> {t('cart.cartItemErrorTableTitle')}</strong>}
                  type="error"
                  showIcon
                  closable
                />
                <Table columns={columns} dataSource={dataSource} size={'small'} pagination={false} />
                {nbOfferPriceChanged > 0 && (
                  <Flex align="right" gap="middle">
                    <Button type="primary" onClick={updateNewOfferPrice} disabled={false} loading={loading}>
                      Mettre à jour les nouveaux prix
                    </Button>
                  </Flex>
                )}
              </Flex>
            </Col>
          </Row>
        </>
      )}
      <Row className={styles.header} id={CART_HEADER_ID} gutter={10}>
        <Col>
          <ShoppingCartOutlined className={styles.icon} />
          <Text type="title" className={styles.text}>
            {title}
          </Text>
        </Col>
        <Col>
          <Text type="title" className={styles.text}>
            {'-'}
          </Text>
        </Col>
        <Col>
          <div className={`${styles[status || SavedCartStatusEnum.IN_PROGRESS]} ${styles.status}`}>
            {status
              ? t(`cartRequests.${camelCase(status)}`)
              : t(`cartRequests.${camelCase(SavedCartStatusEnum.IN_PROGRESS)}`)}
          </div>
        </Col>
        <Col>
          {env('allowPlainCartItem') && (
            <Tooltip>
              <Button
                className={styles.button}
                type="primary"
                ghost
                shape="circle"
                size="large"
                onClick={openAddProductModal}
                icon={<PlusOutlined />}
              />
            </Tooltip>
          )}
          { status !== SavedCartStatusEnum.CONVERTED && <DeleteAllProducts /> }
          <ExpandAllProducts />
        </Col>
      </Row>
    </>
  );
};

export default CartHeader;
