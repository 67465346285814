import { ErrorType, ICustomer } from 'types';
import { AppThunkDispatch, useAppSelector } from 'store/store';
import { sendMessage } from 'store/messages';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import React, { FC, FunctionComponent, useCallback, useEffect, useState } from 'react';
import { useAuth } from 'hooks/useAuth';
import { Col, Input, Layout, Pagination, Popconfirm, Row, Space, Table as AntdTable } from 'antd';
import { fetchAtcUserByUsername } from '../../store/user';
import {
  addCustomerToWhitelist,
  fetchCustomersAvailableToWhitelist,
  fetchCustomersWhitelistByAtcCode,
  removeCustomerFromWhitelist,
  selectCustomerList,
  selectWhitelistByAtcCode,
  selectWhitelistCustomers,
  setCustomerListFilter,
  setWhitelistByAtcCodeAtcCode,
  setWhitelistByAtcCodePage,
} from '../../store/customers';
import useDebounce from '../../hooks/useDebounce';
import { useToken } from '../../cookies';
import styles from './EditUserWhitelist.module.scss';

const CustomersFilter: React.FC<{ atcCode: string }> = (props: { atcCode: string }) => {
  const dispatch = useDispatch();
  const token = useToken();

  const [filter, setFilter] = useState<string>('');
  const [showError, setShowError] = useState(false);
  const { t } = useTranslation();
  const minChar = 3;

  const debouncedFilter = useDebounce(filter, 300);

  const handleFilter = (value: string) => {
    setFilter(value);
    dispatch(fetchCustomersAvailableToWhitelist({ filter: value, token: token.token, atcCode: props.atcCode }));
  };

  const filterCustomers = useCallback(() => {
    dispatch(setCustomerListFilter(debouncedFilter));
  }, [debouncedFilter, dispatch]);

  useEffect(() => {
    if (debouncedFilter.length >= minChar || debouncedFilter.length === 0) {
      filterCustomers();
    }

    setShowError(() => debouncedFilter.length < minChar && debouncedFilter.length !== 0);
  }, [debouncedFilter, dispatch, filterCustomers]);

  return (
    <div className={styles.wrapper}>
      {showError && <span className={styles.error}>{t('errors.minimumFourCharacters', { count: 3 })}</span>}
      <Input
        placeholder={t('landing.searchHere').toString()}
        size="large"
        className={styles.input}
        onChange={(event) => handleFilter(event.target.value)}
      />
    </div>
  );
};

const CustomersContainer = () => (
  <Layout.Content className={styles.container}>
    <div className={styles.main}>
      <CustomerList />
    </div>
  </Layout.Content>
);

const CustomerList: FC = () => {
  const { t } = useTranslation();
  const { isLoading, customers } = useAppSelector(selectCustomerList);
  const { atcCode, pageSize } = useAppSelector(selectWhitelistByAtcCode);
  const dispatch = useDispatch<AppThunkDispatch>();

  const addCb = (customerIdentifier: string) => {
    dispatch(addCustomerToWhitelist({ customerIdentifier, atcCode }))
      .then(() => {
        dispatch(sendMessage({ message: t('users.successAddToWhitelist'), type: 'success' }));
      })
      .catch((error) => {
        dispatch(sendMessage({ message: (error as ErrorType).message, type: 'error' }));
      });
  };

  const TABLE_COLUMNS: TableColumnDefinition<ICustomer>[] = [
    { title: t('users.email'), dataIndex: 'email', width: '25%' },
    {
      title: t('users.fullname'),
      dataIndex: undefined,
      render: (customer) => (
        <span>
          {customer.firstName} {customer.lastName}
        </span>
      ),
      width: '25%',
    },
    { title: t('users.customerIdentifier'), dataIndex: 'customerIdentifier', width: '25%' }, //todo: add translation
    {
      title: t('users.actions'),
      dataIndex: undefined,
      width: '25%',
      render: (record) => (
        <Space size="middle">
          <Popconfirm
            title={t('users.confirmAddToWhitelist')}
            onConfirm={addCb.bind(null, record.customerIdentifier)}
            okText="Yes"
            cancelText="No"
          >
            <a href="#">{t('users.add')}</a>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <AntdTable
      className={styles.container__table}
      rowKey={'Username'}
      columns={TABLE_COLUMNS}
      dataSource={customers}
      pagination={false}
      loading={isLoading}
    />
  );
};

export const EditUserWhitelist: FunctionComponent = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppThunkDispatch>();
  const { username } = useParams<{ username: string }>();
  const { getUser } = useAuth();
  const token = useToken();
  const customers = useAppSelector(selectWhitelistCustomers);
  const { isLoading, page, total, atcCode, pageSize } = useAppSelector(selectWhitelistByAtcCode);

  useEffect(() => {
    if (username === 'new') return;
    getUser(username as string)
      .then((user) => {
        if (!user) return;
        const email = user.username;
        console.log(email);
        dispatch(fetchAtcUserByUsername({ username: email }))
          .then((atcResponse) => {
            const atc = atcResponse.payload;
            if (!atc) return;
            dispatch(setWhitelistByAtcCodeAtcCode(atc.code as string));
            const skip = (page - 1) * pageSize;
            dispatch(
              fetchCustomersWhitelistByAtcCode({ atcCode: atc.code as string, skip, pageSize, token: token.token }),
            ).catch((error) => {
              dispatch(sendMessage({ message: (error as ErrorType).message, type: 'error' }));
              return;
            });
          })
          .catch((error) => {
            dispatch(sendMessage({ message: (error as ErrorType).message, type: 'error' }));
            return;
          });
      })
      .catch((error) => {
        dispatch(sendMessage({ message: (error as ErrorType).message, type: 'error' }));
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [username, page, pageSize]);

  useEffect(() => {
    dispatch(
      setWhitelistByAtcCodePage({
        page: 1,
        pageSize: pageSize,
      }),
    );
  }, [dispatch, username]);
  function deleteCb(customerIdentifier: string) {
    dispatch(removeCustomerFromWhitelist({ customerIdentifier, atcCode }))
      .then(() => {
        dispatch(sendMessage({ message: t('users.successDeleteFromWhitelist'), type: 'success' }));
      })
      .catch((error) => {
        dispatch(sendMessage({ message: (error as ErrorType).message, type: 'error' }));
      });
  }

  const TABLE_COLUMNS: TableColumnDefinition<ICustomer>[] = [
    { title: t('users.email'), dataIndex: 'email', width: '25%' },
    {
      title: t('users.fullname'),
      dataIndex: undefined,
      render: (customer) => (
        <span>
          {customer.firstName} {customer.lastName}
        </span>
      ),
      width: '25%',
    },
    { title: t('users.customerIdentifier'), dataIndex: 'customerIdentifier', width: '25%' },
    {
      title: t('users.actions'),
      dataIndex: undefined,
      width: '25%',
      render: (record) => (
        <Space size="middle">
          <Popconfirm
            title={t('users.confirmDeleteFromWhitelist')}
            onConfirm={deleteCb.bind(null, record.customerIdentifier)}
            okText="Yes"
            cancelText="No"
          >
            <a href="#">{t('users.delete')}</a>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <Layout.Content className={styles.whitelist}>
      <Row className={styles.content}>
        <h3 className={styles.title}>{t('users.editCustomerWhitelistPageTitle')}</h3>
        <Col className={styles.col}>
          <Row className={styles.row}>
            <Col span={14}>
              <Row className={styles.row}>
                <h4 className={styles.title}>
                  {t('users.editCustomerWhitelistExisting')} ({total})
                </h4>
                <AntdTable
                  className={styles.container__table}
                  rowKey={'Username'}
                  columns={TABLE_COLUMNS}
                  dataSource={customers}
                  pagination={false}
                  loading={isLoading}
                />
              </Row>
              <Row className={styles.row}>
                {customers.length > 0 && (
                  <Pagination
                    className={styles.container__pagination}
                    current={page}
                    defaultCurrent={page}
                    total={total}
                    disabled={false}
                    defaultPageSize={pageSize}
                    pageSizeOptions={['20', '30', '50', '100']}
                    onChange={(currentPage, currentSize) => {
                      dispatch(
                        setWhitelistByAtcCodePage({
                          page: currentPage,
                          pageSize: currentSize,
                        }),
                      );
                    }}
                  />
                )}
              </Row>
            </Col>
            <Col className={styles.col} span={10}>
              <Row className={styles.row}>
                <Col className={styles.col} span={12}>
                  <h4 className={styles.title}>{t('users.editCustomerWhitelistAdd')}</h4>
                </Col>
                <Col className={styles.col} span={12}>
                  <CustomersFilter atcCode={atcCode} />
                </Col>
              </Row>
              <Row className={styles.row}>
                <CustomersContainer />
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </Layout.Content>
  );
};
export default EditUserWhitelist;
