import { FC } from 'react';
import { Modal } from 'components/shared';
import { Radio } from 'antd';
import DatesDropdown from '../DatesDropdown';
import AddressContent from '../AddressContent';
import { IPostalCodeModalProps } from './types';
import styles from './styles.module.scss';

const PostalCodeModal: FC<IPostalCodeModalProps> = (props: IPostalCodeModalProps) => {
  const { onClose, visible, pointsList, onPickAddress, selectedId } = props;

  return (
    <Modal
      styles={{
        body: {
          borderRadius: '10px',
          background: 'white',
        },
      }}
      width={'auto'}
      footer={null}
      open={visible}
      onCancel={onClose}
      title="Choisissez le point relais"
    >
      <div className={styles.container}>
        <Radio.Group className={styles.group} value={selectedId} onChange={(e) => onPickAddress(e.target.value)}>
          {pointsList?.map((item) => (
            <div key={item.id} className={styles.address}>
              <Radio value={item.id}>
                <AddressContent {...item} />
              </Radio>
              <DatesDropdown openingDays={item.dailyOpening} closingDays={item.closingDays} />
            </div>
          ))}
        </Radio.Group>
      </div>
    </Modal>
  );
};

export default PostalCodeModal;
