import { clearCartState, getCartLastUnitPrices, selectCartId } from 'store/cart';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router';
import { useSelector } from 'react-redux';
import { useErrorBoundary } from 'react-error-boundary';
import React, { FC, ReactNode, useEffect, useState } from 'react';
import { Spin } from 'antd';
import { unwrap } from '../../utils/api.utils';
import { ErrorType } from '../../types';
import { selectUserTcCode } from '../../store/user';
import { useAppDispatch, useAppSelector } from '../../store/store';
import { clearSavedCartState, restoreSavedCart } from '../../store/savedCart';
import { clearFetchedTowns, clearPostalCodes, clearProductsState } from '../../store/products';
import { clearHistoricalProductSearchState } from '../../store/historicalProductSearch/reducer';
import { clearDeliveryState } from '../../store/delivery';
import { clearShippingAddresses } from '../../store/customers';
import { clearCartRequestsState, fetchCartRequest } from '../../store/cartRequests';
import { SavedCartService } from '../../services/savedCart.service';
import { CartService } from '../../services/cart.service';
import { usePermissions } from '../../hooks';
import { getOrderPath, OrderRoute } from '../../constants/routes';
import styles from './Preloader.module.scss';

type Props = {
  children: ReactNode;
  redirectOnCartId?: boolean;
};

export const Preloader: FC<Props> = ({ children, redirectOnCartId = false }: Props) => {
  const { cartId } = useParams();
  const savedCartId = useAppSelector(selectCartId);
  const dispatch = useAppDispatch();
  const tcCode = useSelector(selectUserTcCode);
  const [isLoading, setLoading] = useState(true);
  const navigate = useNavigate();
  const { showBoundary } = useErrorBoundary();

  const { allowed: allowedToRestore } = usePermissions(['QUOTE_RESTORE'], false, false);
  const { allowed: allowedToRestoreOtherTC } = usePermissions(['QUOTE_CAN_LIST_TC_QUOTE'], false, false);

  const restoreCart = async (restoreCartId: string) => {
    /**
     * Clear all states before restoring the cart
     */
    await Promise.allSettled([
      dispatch(clearDeliveryState()),
      dispatch(clearCartState()),
      dispatch(clearCartRequestsState()),
      dispatch(clearHistoricalProductSearchState()),
      dispatch(clearSavedCartState()),
      dispatch(clearProductsState()),
      dispatch(clearShippingAddresses()),
      dispatch(clearFetchedTowns()),
      dispatch(clearPostalCodes()),
    ]);
    /**
     * Gather all the data required to restore the cart
     */

    const savedCart = unwrap(await SavedCartService.get(restoreCartId));
    /**
     * Checking permission to restore the cart if the cart belongs to another TC
     * @TODO this should be done on the backend
     */
    if (savedCart.assignedTcCode && String(savedCart.assignedTcCode) !== String(tcCode) && !allowedToRestoreOtherTC) {
      showBoundary({
        code: 1003,
      });
      return;
    }
    const lastUnitPrices = unwrap(await CartService.getCartLastUnitPrices(restoreCartId));
    /**
     * Dispatch the data to the Redux store
     */
    await dispatch(restoreSavedCart(savedCart));
    await dispatch(getCartLastUnitPrices(lastUnitPrices));
    savedCart.cartRequest && (await dispatch(fetchCartRequest(savedCart.cartRequest)));
  };

  useEffect(() => {
    /**
     * If there is an cartId in the URL and the cartId is different
     * from the one in the Redux store it means the cart is not
     * loaded yet, and we need to restore it
     */
    if (
          cartId
          && cartId !== savedCartId
    ) {
      /**
       * Checking permission to restore the cart
       */
      if (!allowedToRestore) {
        showBoundary({
          code: 1003,
        });
        return;
      }
      restoreCart(cartId)
        .then(() => {
          /**
           * Set the loading state to false
           */
          setLoading(false);
          /**
           * Redirect to the product route if the redirectOnCartId prop is set
           * We do this because delivery & validation needs to run again after
           * restoring the cart for the whole cart to be valid
           */
          if (redirectOnCartId) {
            navigate(getOrderPath(OrderRoute.PRODUCT, cartId));
          }
        })
        .catch((e: ErrorType) => {
          showBoundary(e);
        });
    }
    /**
     * there are no deps here because we only want to run this effect once on mount
     */
  }, []);

  /**
   * If there is no cartId in the URL or the cart is already loaded we render the children
   */

  if (!cartId || !isLoading || cartId === savedCartId) {
    return <>{children}</>;
  }
  /**
   * If the cart is not loaded, yet we show a loading spinner
   */

  if (isLoading) {
    return (
      <div className={styles.loading}>
        <Spin size={'large'} />
      </div>
    );
  }

  /**
   * If there is an error we don't show anything and just return null
   * The error will be handled by the ErrorBoundary
   */
  return null;
};
