import { clearUserState } from 'store/user';
import { clearSettingsState } from 'store/settings';
import { clearSavedCartState } from 'store/savedCart';
import { clearProductsState } from 'store/products';
import { clearOrderHistory } from 'store/ordersHistory';
import { clearMessagesState } from 'store/messages';
import { clearDeliveryState } from 'store/delivery';
import { clearCustomersState } from 'store/customers';
import { clearCartRequestsState } from 'store/cartRequests';
import { clearCartState } from 'store/cart';

import { useDispatch } from 'react-redux';
import { FC } from 'react';
import { useLogout } from 'hooks';
import { clearHistoricalProductSearchState } from '../../../store/historicalProductSearch/reducer';
import styles from './NavigationBar.module.scss';
import NavBarItems from './Components/NavBarItems';

const NavigationBar: FC = () => {
  const dispatch = useDispatch();
  const { logout } = useLogout();

  const onClickLogout = () => {
    logout();
    dispatch(clearUserState());
    dispatch(clearProductsState());
    dispatch(clearOrderHistory());
    dispatch(clearMessagesState());
    dispatch(clearDeliveryState());
    dispatch(clearCartState());
    dispatch(clearHistoricalProductSearchState());
    dispatch(clearCartRequestsState());
    dispatch(clearSavedCartState());
    dispatch(clearCustomersState());
    dispatch(clearSettingsState());
  };

  return (
    <div className={styles.sidebar}>
      <NavBarItems onLogout={onClickLogout} />
    </div>
  );
};

export default NavigationBar;
