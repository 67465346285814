export const cart_fr = {
  cartAttachmentUploadSuccess: '({{count}}) files has been uploaded.',
  noShippingMethods: 'No shipping methods',
  qtyEditableCheckboxLabel: 'Qté éditable',
  qtyEditableCheckboxTooltip: 'Le client pourra éditer la quantité avant de valider (dans la limite du stock).',
  removableCheckboxLabel: 'Suppression possible',
  removableCheckboxTooltip: "Le client pourra supprimer l'article avant de valider le devis.",
  replaceOffer: 'Remplacer l’offre actuelle pour le produit',
  replaceOfferAddToCart: 'Ajouter le produit au panier',
  replace: 'Remplacer',
  articleInclusive: "Le client ne verra pas l'article s'il est exclu mais restera présent dans le devis",
  uploadMore: 'Télécharger plus de fichiers',
  cartAttachments: 'Pièces jointes',
  discountTooltip: 'Appliquer une remise',
  commentTooltip: 'Ajouter un commentaire au produit',
  warehouseStockTooltip: 'Stock entrepôt',
  deleteTooltip: 'Supprimer le produit',
  checkOfferErrorMessageNotFound: "Cette offre n'est plus disponible.",
  checkOfferErrorMessageNoStock: '{{requiredStock}} (stock requis) > {{availableStock}} (stock disponible)',
  checkOfferErrorMessagePriceUpdated: 'Le prix de cette offre est passé de {{currentOfferPrice}} à {{newOfferPrice}}.',
  checkOfferErrorMessageMargin: 'La marge ne peut être négative et le prix doit être > 0.',
  OFFER_PRICE_UPDATED: 'Prix a changé',
  OFFER_NO_STOCK: 'Stock insuffisant',
  NO_OFFER_FOUND: 'Offre expirée',
  OFFER_MARGIN_ISSUE: 'Marge négative',
  cartItemErrorTableTitle: 'Les lignes ci-dessous peuvent poser problème...',
  historicalProductSearchTableHeaderProduct: 'Produit / Code',
  historicalProductSearchTableHeaderQuantity: 'Quantité',
  historicalProductSearchTableHeaderPrice: 'Prix Unit.',
  historicalProductSearchTableHeaderDocumentNumber: 'N° Document',
  historicalProductSearchTableHeaderDate: 'Date',
};
