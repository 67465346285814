import { createTranslation } from 'utils/translation.utils';
import { unwrap } from 'utils/api.utils';
import {
  handleCatchError,
  isFetchProductSearchCustomerQuotationResponse,
  isFetchProductSearchMultiResponse,
  isFulfilledAction,
  isPendingAction,
  isRejectedAction,
} from 'utils';
import { IProductType, MultiSearchHeader, Unit } from 'types/product';
import { FetchCartRequestAndCreateStateResponse } from 'types/cartRequest';
import { ErrorType, ITown } from 'types';
import { RestoreSavedCartStateResponse } from 'store/savedCart/types';
import { restoreSavedCartMatcher } from 'store/savedCart/matcher';
import { fetchDeparturesNormalizer, fetchProductsNormalizer } from 'store/products/normalizers';
import {
  fetchProductReplacementsAdapter,
  fetchProductsAdapter,
  fetchProductStateAdapter,
} from 'store/products/adapters/fetchProductsAdapter';
import { fetchTownAdapter, fetchTownStateAdapter } from 'store/products/adapters';
import { CART_REDUCER_NAME, isNewOrderMatcher } from 'store/common';
import { fetchCartRequestAndCreateStateMatcher } from 'store/cartRequests/matcher';
import { IChangePostcodeParams, IFetchTownResponse, IFetchTownsParams } from 'store/cart/types';
import { SupplierSerivce } from 'services/supplier.service';
import { ProductsService } from 'services/products.service';
import { PostcodeApiService } from 'services/postcodeServiceApi';
import produce from 'immer';
import { AsyncThunk, createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../rootReducer';
import { getShippingAddresses } from '../delivery';
import { DisplayOfferGroup } from '../../types/offer';
import { DepartureService } from '../../services/departure.service';
import { ERRORS } from '../../constants/errors';
import { DiscountType } from '../../components/shared/Discount/Discount.types';
import {
  FetchDeparturesResponse,
  FetchSuppliersParams,
  FetchSuppliersResponse,
  IFetchProductsParams,
  IFetchProductsResponse,
  IFetchProductsResponseWithSearchType,
  IProductsState,
  IWarehouseStockDataItem,
  MultiSearchResult, REPLACEMENT_MODAL_MODE_ADD_OFFER_TO_CART, REPLACEMENT_MODAL_MODE_CHANGE_OFFER,
} from './types';

export const PRODUCTS_REDUCER_NAME = 'products';

const initialState: IProductsState = {
  isLoading: false,
  displayAllOffers: false,
  excludeNoStockOffers: false,
  displayOfferGroup: DisplayOfferGroup.NONE,
  productError: null,
  postcodeError: null,
  multiSearch: {
    queries: [],
    searchResults: [],
  },
  customerQuotation: {
    searchResults: {},
  },
  searchedVariantsIds: [],
  products: {},
  offers: {
    list: {},
    byProduct: {},
  },
  tableMinimized: false,
  lastSearchReturnedNoResult: false,
  fetchedTowns: [],
  postalCodes: null,
  isLoadingPostalCode: true,
  isClientMode: false,
  isLoadingSuppliers: false,
  suppliers: [],
  warehouseStocks: {
    data: {},
    isLoading: false,
    sku: '',
    warehouseName: '',
    rememberWarehouse: false,
    hideOutOfStock: true,
    visibility: false,
  },
  departures: {},
  replacementModal: {
    mode: null,
    visible: false,
    sku: null,
    productIndex: null,
    displayAllOffers: true,
    quantity: 0,
    isLoading: false,
    searchedVariantIds: [],
    products: {},
    offers: {
      list: {},
      byProduct: {},
    },
  },
  addProductModal: {
    visible: false,
    units: [],
    isLoading: false,
  },
};

const trans = createTranslation('Products');

export const fetchSuppliers: AsyncThunk<FetchSuppliersResponse, FetchSuppliersParams, { rejectValue: ErrorType }> =
  createAsyncThunk(
    `${PRODUCTS_REDUCER_NAME}/fetchSuppliers`,

    async (_, { rejectWithValue }) => {
      try {
        return unwrap(await SupplierSerivce.getSuppliers());
      } catch (err) {
        return handleCatchError(err, rejectWithValue, true);
      }
    },
  );

export const fetchDepartures: AsyncThunk<FetchDeparturesResponse, void, { rejectValue: ErrorType }> = createAsyncThunk(
  `${PRODUCTS_REDUCER_NAME}/fetchDepartures`,

  async (_, { rejectWithValue }) => {
    try {
      return unwrap(await DepartureService.getDepartures());
    } catch (err) {
      return handleCatchError(err, rejectWithValue, true);
    }
  },
);

export const fetchProducts: AsyncThunk<
  IFetchProductsResponseWithSearchType,
  IFetchProductsParams,
  { rejectValue: ErrorType; state: RootState }
> = createAsyncThunk(
  `${PRODUCTS_REDUCER_NAME}/fetchProducts`,

  async (
    {
      search,
      filter,
      page,
      postcode,
      tags,
      displayAllOffers,
      searchType,
      rowKey,
      excludeNoStockOffers,
    }: IFetchProductsParams,
    { rejectWithValue, getState },
  ) => {
    const state = getState();
    const restParams = {
      ...(typeof rowKey !== 'undefined' && { rowKey }),
      searchType,
    };

    if (!search || search === '') {
      return {
        products: [],
        lastSearchReturnedNoResult: false,
      };
    }
    try {
      const productsFetched = fetchProductStateAdapter({ search, filter, page });
      const fetchedProducts = await fetchProductsAdapter(
        productsFetched,
        postcode,
        tags,
        displayAllOffers,
        state.cart.users?.clubMemberExpDate,
        excludeNoStockOffers,
      );

      return {
        ...fetchedProducts,
        ...restParams,
      };
    } catch (err) {
      return handleCatchError(
        {
          ...err,
          ...restParams,
        },
        rejectWithValue,
      );
    }
  },
);

export const fetchReplacements: AsyncThunk<
  IFetchProductsResponse,
  { sku: string; postcode?: string; tags?: string[]; displayAllOffers: boolean; excludeNoStockOffers: boolean },
  { rejectValue: ErrorType; state: RootState }
> = createAsyncThunk(
  `${PRODUCTS_REDUCER_NAME}/fetchReplacements`,
  async ({ sku, postcode, tags, displayAllOffers, excludeNoStockOffers }, { rejectWithValue, getState }) => {
    try {
      const state = getState();

      const products = ProductsService.findBySku(sku, 0, 1000);
      return await fetchProductReplacementsAdapter(
        products,
        postcode,
        tags,
        displayAllOffers,
        state.cart.users?.clubMemberExpDate,
        excludeNoStockOffers,
      );
    } catch (err) {
      return handleCatchError(err, rejectWithValue, true);
    }
  },
);

export const changePostcode: AsyncThunk<IFetchTownResponse, IChangePostcodeParams, { rejectValue: ErrorType }> =
  createAsyncThunk(
    `${PRODUCTS_REDUCER_NAME}/changePostcode`,
    async ({ townId }: IChangePostcodeParams, { rejectWithValue }) => {
      try {
        const town = await fetchTownAdapter(fetchTownStateAdapter({ townId }));
        return { town };
      } catch (err) {
        return handleCatchError(err, rejectWithValue);
      }
    },
  );

export const getWarehouseStocks: AsyncThunk<
  IWarehouseStockDataItem[],
  { sku: string; warehouseName: string; hideOutOfStock?: boolean },
  { rejectValue: ErrorType; state: RootState }
> = createAsyncThunk(
  `${CART_REDUCER_NAME}/getWarehouseStock`,
  async ({ sku, warehouseName, hideOutOfStock }, { rejectWithValue }) => {
    try {
      return unwrap(await ProductsService.getWarehouseStock({ sku, warehouseName, hideOutOfStock }));
    } catch (err) {
      return handleCatchError(err, rejectWithValue);
    }
  },
);

export const fetchTowns: AsyncThunk<Array<ITown>, IFetchTownsParams, { rejectValue: ErrorType }> = createAsyncThunk(
  `${PRODUCTS_REDUCER_NAME}/fetchTowns`,
  async ({ postcode }: IFetchTownsParams, { rejectWithValue }) => {
    try {
      return unwrap(await PostcodeApiService.searchTowns(postcode));
    } catch (err) {
      return handleCatchError(err, rejectWithValue);
    }
  },
);

export const getUnits: AsyncThunk<Unit[], { locale?: string }, { rejectValue: ErrorType }> = createAsyncThunk(
  `${PRODUCTS_REDUCER_NAME}/getUnits`,
  async ({ locale }, { rejectWithValue }) => {
    try {
      return unwrap(await ProductsService.getUnits(locale || 'fr'));
    } catch (err) {
      return handleCatchError(err, rejectWithValue);
    }
  },
);

const productsSlice = createSlice({
  name: PRODUCTS_REDUCER_NAME,
  initialState,
  reducers: {
    clearProductsState() {
      return { ...initialState };
    },
    productsCleared: (state) => {
      state.searchedVariantsIds = initialState.searchedVariantsIds;
      state.products = initialState.products;
      state.lastSearchReturnedNoResult = initialState.lastSearchReturnedNoResult;
      state.offers = initialState.offers;
    },
    tableMinimizedSet: (state, { payload }: PayloadAction<boolean>) => {
      state.tableMinimized = payload;
    },
    checkPriceByQuantity(
      state,
      {
        payload: { productType, offerId, price, discount, type, rowKey },
      }: PayloadAction<{
        productType: IProductType;
        offerId: number;
        price: number;
        discount: number;
        type: DiscountType;
        rowKey: number;
      }>,
    ) {
      if (productType === 'default') {
        state.offers.list[offerId].price.price = price;
        state.offers.list[offerId].price.discountValue = discount;
        state.offers.list[offerId].price.discountType = type;
      }

      if (productType === 'multi' && rowKey) {
        state.multiSearch.searchResults[rowKey].offers.list[offerId].price.price = price;
        state.multiSearch.searchResults[rowKey].offers.list[offerId].price.discountValue = discount;
        state.multiSearch.searchResults[rowKey].offers.list[offerId].price.discountType = type;
      }

      if (productType === 'customer-quotation' && rowKey) {
        state.customerQuotation.searchResults[rowKey].offers.list[offerId].price.price = price;
        state.customerQuotation.searchResults[rowKey].offers.list[offerId].price.discountValue = discount;
        state.customerQuotation.searchResults[rowKey].offers.list[offerId].price.discountType = type;
      }
    },
    setReplacementPrice(
      state,
      {
        payload: { offerId, price },
      }: PayloadAction<{
        offerId: number;
        price: number;
      }>,
    ) {
      state.replacementModal.offers.list[offerId].price.price = price;
    },
    clearPostalCodes: (state) => {
      state.postalCodes = null;
    },
    clearFetchedTowns: (state) => {
      state.fetchedTowns = initialState.fetchedTowns;
      state.productError = initialState.productError;
    },
    setFetchedTowns: (state, { payload: towns }: PayloadAction<Array<ITown>>) => {
      state.fetchedTowns = towns;
    },
    setWarehouseStockModalSku(state, { payload: sku }: PayloadAction<string>) {
      state.warehouseStocks.sku = sku;
    },
    setWarehouseRememberName(state, { payload: remember }: PayloadAction<boolean>) {
      state.warehouseStocks.rememberWarehouse = remember;
    },
    setWarehouseName(state, { payload: name }: PayloadAction<string>) {
      state.warehouseStocks.warehouseName = name;
    },
    setWarehouseStockModalVisibility(state, { payload: visibility }: PayloadAction<boolean>) {
      state.warehouseStocks.visibility = visibility;
    },
    clearMultiSearchQueries: (state) => {
      state.multiSearch = produce(initialState.multiSearch, (draft) => draft);
    },
    setMultiSearchQueries: (state, { payload }: PayloadAction<Array<MultiSearchHeader>>) => {
      state.multiSearch.queries = payload;
      state.multiSearch.searchResults = payload.map(() => {
        return {
          products: [],
          offers: [],
          searchedVariantsIds: [],
        } as unknown as MultiSearchResult;
      });
    },
    setClientMode: (state, { payload: value }: PayloadAction<boolean>) => {
      state.isClientMode = value;
    },
    setDisplayAllOffers: (state, { payload: value }: PayloadAction<boolean>) => {
      state.displayAllOffers = value;
    },
    setExcludeNoStockOffers: (state, { payload: value }: PayloadAction<boolean>) => {
      state.excludeNoStockOffers = value;
    },
    setOffersGroupBy: (state, { payload: value }: PayloadAction<DisplayOfferGroup>) => {
      state.displayOfferGroup = value;
    },
    setPostalCodeLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoadingPostalCode = payload;
    },
    setPostcodeError: (state, { payload }: PayloadAction<ErrorType | null>) => {
      state.postcodeError = payload;
    },
    setReplacementModalVisible: (state, { payload }: PayloadAction<boolean>) => {
      state.replacementModal.visible = payload;
    },
    setReplacementModalSku: (state, { payload }: PayloadAction<string | null>) => {
      state.replacementModal.sku = payload;
    },
    setReplacementModalMode: (state, { payload }: PayloadAction<REPLACEMENT_MODAL_MODE_ADD_OFFER_TO_CART | REPLACEMENT_MODAL_MODE_CHANGE_OFFER | null>) => {
      state.replacementModal.mode = payload;
    },
    setReplacementModalQuantity: (state, { payload }: PayloadAction<number>) => {
      state.replacementModal.quantity = payload;
    },
    setReplacementModalProductIndex: (state, { payload }: PayloadAction<number | null>) => {
      state.replacementModal.productIndex = payload;
    },
    setReplacementModalDisplayAllOffers: (state, { payload }: PayloadAction<boolean>) => {
      state.replacementModal.displayAllOffers = payload;
    },
    setAddProductModalVisible: (state, { payload }: PayloadAction<boolean>) => {
      state.addProductModal.visible = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSuppliers.fulfilled, (state, { payload }: PayloadAction<FetchSuppliersResponse>) => {
      state.isLoadingSuppliers = false;
      state.suppliers = payload;
    });
    builder.addCase(fetchDepartures.fulfilled, (state, { payload }: PayloadAction<FetchDeparturesResponse>) => {
      state.isLoading = false;
      state.departures = fetchDeparturesNormalizer(payload);
    });
    builder.addCase(fetchDepartures.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchSuppliers.rejected, (state) => {
      state.suppliers = initialState.suppliers;
      state.isLoadingSuppliers = false;
    });

    builder.addCase(fetchSuppliers.pending, (state) => {
      state.isLoadingSuppliers = true;
    });

    builder.addCase(
      fetchProducts.fulfilled,
      (state, { payload }: PayloadAction<IFetchProductsResponseWithSearchType>) => {
        const { normalizedProducts, normalizedOffers, searchedVariantsIds } = fetchProductsNormalizer(payload);

        if (isFetchProductSearchCustomerQuotationResponse(payload)) {
          const { rowKey } = payload;
          state.customerQuotation.searchResults[rowKey] = {
            products: normalizedProducts,
            offers: {
              byProduct: normalizedOffers.products,
              list: normalizedOffers.offers,
            },
            searchedVariantsIds,
          };
        } else if (isFetchProductSearchMultiResponse(payload)) {
          const { rowKey } = payload;
          state.multiSearch.searchResults.splice(rowKey, 1, {
            products: normalizedProducts,
            offers: {
              byProduct: normalizedOffers.products,
              list: normalizedOffers.offers,
            },
            searchedVariantsIds,
          });
        } else {
          state.searchedVariantsIds = searchedVariantsIds;
          state.products = normalizedProducts;
          state.offers.list = normalizedOffers.offers;
          state.offers.byProduct = normalizedOffers.products;
        }
      },
    );
    builder.addCase(fetchReplacements.fulfilled, (state, { payload }: PayloadAction<IFetchProductsResponse>) => {
      const { normalizedProducts, normalizedOffers, searchedVariantsIds } = fetchProductsNormalizer(payload);
      state.replacementModal.isLoading = false;
      state.replacementModal.searchedVariantIds = searchedVariantsIds;
      state.replacementModal.products = normalizedProducts;
      state.replacementModal.offers.list = normalizedOffers.offers;
      state.replacementModal.offers.byProduct = normalizedOffers.products;
    });
    builder.addCase(fetchReplacements.pending, (state) => {
      state.replacementModal.isLoading = true;
      state.replacementModal.searchedVariantIds = [];
      state.replacementModal.products = {};
      state.replacementModal.offers.list = {};
      state.replacementModal.offers.byProduct = {};
    });
    builder.addCase(fetchReplacements.rejected, (state) => {
      state.replacementModal.isLoading = false;
    });

    builder.addCase(getWarehouseStocks.pending, (state) => {
      state.warehouseStocks.isLoading = true;
    });

    builder.addCase(getWarehouseStocks.rejected, (state) => {
      state.warehouseStocks.isLoading = false;
    });

    builder.addCase(getWarehouseStocks.fulfilled, (state, { payload }) => {
      state.warehouseStocks.isLoading = false;
      if (state.warehouseStocks.sku) {
        state.warehouseStocks.data[state.warehouseStocks.sku] = payload;
      }
    });
    builder.addCase(getShippingAddresses.pending, (state) => {
      state.isLoadingPostalCode = true;
    });
    builder.addCase(getShippingAddresses.fulfilled, (state, { payload }) => {
      if (payload.foundTown) state.postalCodes = payload.foundTown;
      state.isLoadingPostalCode = false;
    });
    builder.addCase(changePostcode.fulfilled, (state, { payload: { town } }) => {
      state.postalCodes = town;
      state.isLoadingPostalCode = false;
    });
    builder.addCase(changePostcode.pending, (state) => {
      state.isLoadingPostalCode = true;
    });
    builder.addCase(fetchTowns.fulfilled, (state, { payload }) => {
      state.fetchedTowns = payload;
      state.postcodeError = null;

      if (payload.length === 0) {
        state.postcodeError = ERRORS.towns.noTowns;
      }
    });
    builder.addCase(getUnits.pending, (state) => {
      state.addProductModal.isLoading = true;
    });
    builder.addCase(getUnits.fulfilled, (state, { payload }) => {
      state.addProductModal.units = payload;
      state.addProductModal.isLoading = false;
    });
    builder.addCase(getUnits.rejected, (state) => {
      state.addProductModal.isLoading = false;
    });
    builder.addMatcher(isPendingAction(`${PRODUCTS_REDUCER_NAME}/`), (state) => {
      state.lastSearchReturnedNoResult = false;
      state.isLoading = true;
      state.productError = null;
      state.postcodeError = null;
    });
    builder.addMatcher(isRejectedAction(`${PRODUCTS_REDUCER_NAME}/fetchProducts`), (state, { payload }) => {
      if (typeof payload?.rowKey !== 'undefined') {
        state.multiSearch.searchResults[payload.rowKey] = {
          products: [],
          offers: [],
          searchedVariantsIds: [],
        } as unknown as MultiSearchResult;
      }
      state.isLoading = false;
      state.productError = payload;
      state.lastSearchReturnedNoResult = payload.code === 1;
      state.searchedVariantsIds = [];
      state.products = {};
      state.offers = {
        list: {},
        byProduct: {},
      };
    });
    builder.addMatcher(isRejectedAction(`${PRODUCTS_REDUCER_NAME}/fetchTowns`), (state, { payload }) => {
      state.isLoading = false;
      state.fetchedTowns = [];
      state.postcodeError = payload;
    });
    builder.addMatcher(isRejectedAction(`${PRODUCTS_REDUCER_NAME}/changePostcode`), (state, { payload }) => {
      state.isLoading = false;
      state.postcodeError = payload;
      state.isLoadingPostalCode = false;
    });
    builder.addMatcher(isFulfilledAction(`${PRODUCTS_REDUCER_NAME}/`), (state) => {
      state.isLoading = false;
      state.productError = null;
    });

    builder.addMatcher(
      restoreSavedCartMatcher(),
      (state, { payload }: PayloadAction<RestoreSavedCartStateResponse>) => {
        Object.assign<IProductsState, IProductsState>(state, initialState);

        const {
          state: { products: data },
        } = payload;

        Object.assign<IProductsState, RestoreSavedCartStateResponse['state']['products']>(state, data);
      },
    );

    builder.addMatcher(
      fetchCartRequestAndCreateStateMatcher(),
      (state, { payload }: PayloadAction<FetchCartRequestAndCreateStateResponse>) => {
        const {
          cartRequestState: { products },
        } = payload;

        Object.assign<IProductsState, IProductsState>(state, initialState);
        Object.assign<IProductsState, FetchCartRequestAndCreateStateResponse['cartRequestState']['products']>(
          state,
          products,
        );
      },
    );

    builder.addMatcher(isNewOrderMatcher(), (state) => {
      state.isClientMode = initialState.isClientMode;
    });
  },
});
export const {
  productsCleared,
  tableMinimizedSet,
  setFetchedTowns,
  clearFetchedTowns,
  checkPriceByQuantity,
  clearProductsState,
  setWarehouseStockModalSku,
  setWarehouseRememberName,
  setWarehouseName,
  setWarehouseStockModalVisibility,
  setMultiSearchQueries,
  clearMultiSearchQueries,
  setPostalCodeLoading,
  setClientMode,
  setDisplayAllOffers,
  setExcludeNoStockOffers,
  setOffersGroupBy,
  clearPostalCodes,
  setPostcodeError,
  setReplacementModalVisible,
  setReplacementModalSku,
  setReplacementModalMode,
  setReplacementModalQuantity,
  setReplacementModalProductIndex,
  setReplacementPrice,
  setReplacementModalDisplayAllOffers,
  setAddProductModalVisible,
} = productsSlice.actions;
export default productsSlice.reducer;
