import { ActionType, Log } from 'types/logs';
import { selectCartLogs } from 'store/cart';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useCallback, useMemo, FC } from 'react';
import moment from 'moment';
import { camelCase } from 'lodash';
import { useDateFormat } from 'hooks/useDateFormat';
import { Timeline } from 'antd';
import type { TimelineItemProps } from 'antd/es/timeline/TimelineItem';
import { priceParser } from '../../../utils';

const CartLogs: FC = () => {
  const logs = useSelector(selectCartLogs);
  const { t } = useTranslation();
  const localeDateFormat = useDateFormat('long');

  const getParamsFromLog = useCallback(
    (log: Log) => {
      let params = { ...log.params };
      if (log.action === ActionType.STATUS_UPDATE) {
        const fromStatus = (params.FROM_STATUS || '') as string;
        const toStatus = (params.TO_STATUS || '') as string;
        params = {
          FROM_STATUS: t(`cartRequests.${camelCase(fromStatus)}`),
          TO_STATUS: t(`cartRequests.${camelCase(toStatus)}`),
        };
      }
      if (params.FROM_PRICE && params.TO_PRICE) {
        params = {
          ...params,
          FROM_PRICE: priceParser(Number(params.FROM_PRICE)),
          TO_PRICE: priceParser(Number(params.TO_PRICE)),
        };
      }

      return params;
    },
    [t],
  );

  return useMemo(() => {
    if (!logs?.length) {
      return <div />;
    }

    const items: TimelineItemProps[] = [];
    logs.forEach((log) => {
      const params = getParamsFromLog(log);

      let color = 'blue';
      switch (log.action) {
        case 'CREATE':
          color = 'green';
          break;
        case 'DELETE':
          color = 'red';
          break;
        case 'UPDATE':
          color = 'blue';
          break;
      }

      items.push({
        color,
        label: moment(log.createdAt).utc(true).format(localeDateFormat),
        children: t(`logs.${log.message}`, params),
      });
    });

    return <Timeline mode="left" items={items} />;
  }, [logs, getParamsFromLog, localeDateFormat, t]);
};

export default CartLogs;
