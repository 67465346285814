import { getToken } from 'cookies';
import { ApiResponse } from 'apisauce';
import {
  HistoricalProductSearch,
  HistoricalProductSearchFilter
} from '../store/historicalProductSearch/type';
import { config } from '../config';
import { RestServiceApi } from './api.service';

const api = new RestServiceApi(config.atcCheckoutBackendUrl || '');
export class HistoricalProductSearchServiceApi {

  public async search(filters: HistoricalProductSearchFilter): Promise<ApiResponse<HistoricalProductSearch[]>> {
    const auth = api.getAuthenticateHeader(getToken());

    return api.get<HistoricalProductSearchFilter, HistoricalProductSearch[]>(
      '/historical-search-product',
      filters,
      { ...auth }
    );
  }


}

export const HistoricalProductSearchService = new HistoricalProductSearchServiceApi();
