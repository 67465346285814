import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import React, { FC, useEffect, useState } from 'react';
import moment from 'moment/moment';
import { round } from 'lodash';
import { Dayjs } from 'dayjs';
import locale from 'antd/es/date-picker/locale/fr_FR';
import { Button, Form, Input, Space, Table, Tag, Tooltip, DatePicker, Typography, Badge } from 'antd';
import { isFulfilled } from '@reduxjs/toolkit';
import { SearchOutlined, TagsOutlined, UserOutlined } from '@ant-design/icons';
import { SimplePrice } from '../../shared/ProductPrice/SimplePrice';
import { percentage } from '../../../utils/styles.utils';
import { REPLACEMENT_MODAL_MODE } from '../../../store/products/types';
import {
  setReplacementModalDisplayAllOffers,
  setReplacementModalMode,
  setReplacementModalQuantity,
  setReplacementModalSku,
  setReplacementModalVisible,
} from '../../../store/products';
import {
  HistoricalProductSearch, HistoricalProductSearchFilter,
  IHistoricalProductSearchRowKey,
} from '../../../store/historicalProductSearch/type';
import { selectHistoricalProductSearch } from '../../../store/historicalProductSearch/selectors';
import {
  fetchHistoricalProductSearch,
} from '../../../store/historicalProductSearch/reducer';
import { selectSelectedSearchedCustomer } from '../../../store/customers';
import { useDateFormat } from '../../../hooks/useDateFormat';
import { CUSTOM_DATE_FORMAT } from '../../../constants/locale';
import styles from './HistoricalProductSearchTable.module.scss';


export const HistoricalProductSearchTable: FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const products = useSelector(selectHistoricalProductSearch);
  const selectedCustomer = useSelector(selectSelectedSearchedCustomer);
  const dispatch = useDispatch();
  const localeDateFormat = useDateFormat('short');
  const [productCodeFilter, setProductCodeFilter] = useState<string|undefined>(undefined);
  const [startDateFilter, setStartDateFilter] = useState<string|undefined>(undefined);
  const [endDateFilter, setEndDateFilter] = useState<string|undefined>(undefined);
  const [documentNumberFilter, setDocumentNumberFilter] = useState<string|undefined>(undefined);

  const { RangePicker } = DatePicker;
  const { t } = useTranslation();


  useEffect(() => {
    searchHistoricalProduct();
  }, []);


  const searchHistoricalProduct = async () => {
    const filter: HistoricalProductSearchFilter = {
      customerIdentifier : selectedCustomer?.customerIdentifier ?? '',
    };

    if (productCodeFilter && productCodeFilter.length > 0) {
      filter.search = productCodeFilter;
    }

    if (documentNumberFilter && documentNumberFilter.length > 0) {
      filter.documentNumber = documentNumberFilter;
    }

    if (startDateFilter) {
      filter.startDate = startDateFilter;
    }

    if (startDateFilter) {
      filter.endDate = endDateFilter;
    }

    setIsLoading(true);
    const isFullfilled = isFulfilled(await dispatch(fetchHistoricalProductSearch({ filter })));
    if (isFullfilled) {
      setIsLoading(false);
    }

  }

  const handleOpenOffersModal = (sku: string) => {
    dispatch(setReplacementModalSku(sku));
    dispatch(setReplacementModalMode(REPLACEMENT_MODAL_MODE.MODE_ADD_OFFER_TO_CART));
    dispatch(setReplacementModalVisible(true));
    dispatch(setReplacementModalQuantity(1));
    dispatch(setReplacementModalDisplayAllOffers(false));
  //  dispatch(setReplacementModalProductIndex(productIndex));
  };

  const onSelectDate = (dates: [Dayjs | null, Dayjs | null] | null) => {
    let startDate = undefined;
    let endDate = undefined;

    if (dates) {
      const [currentStartDate, currentEndDate] = dates;
      startDate = currentStartDate?.format(CUSTOM_DATE_FORMAT.mysql.short) ?? undefined;
      endDate = currentEndDate?.format(CUSTOM_DATE_FORMAT.mysql.short) ?? undefined;
    }
    setStartDateFilter(startDate);
    setEndDateFilter(endDate);
  }

  const tableColumnsOffers = [
    {
      title: t('cart.historicalProductSearchTableHeaderProduct'),
      dataIndex: 'sku',
      key: 'sku',
      width: percentage(10, 30),
      render: (sku: string, product: HistoricalProductSearch) => {
        return <>
          <p>{product.label}</p>
          <span>
            <Tag color="default">
              {sku}
            </Tag>
            <Tooltip placement="top" title={'TC ' + product.atcName} arrow={true}>
              <Tag icon={<UserOutlined />} color="default">
                {product.atcCode}
              </Tag>
            </Tooltip>
          </span>
        </>
      }
    },
    {
      title: t('cart.historicalProductSearchTableHeaderQuantity'),
      dataIndex: 'quantity',
      key: 'quantity',
      align: 'center',
      render: (quantity: number, product: HistoricalProductSearch) => {
        return <>
          {(product.conditioningQuantity && product.conditioningQuantity !== 0) && <> {round(quantity * (product.conditioningQuantity ?? 0), 2).toLocaleString('fr-FR')} {product.unitLabel}</>}

          <p>
            <Typography.Text className="ant-form-text" type="secondary">{round(quantity, 2).toLocaleString('fr-FR')} {product.conditioning} </Typography.Text>
          </p>
        </>;
      },
    },
    {
      title:  t('cart.historicalProductSearchTableHeaderPrice'),
      dataIndex: 'unitPrice',
      key: 'unitPrice',
      align: 'center',
      render: (unitPrice: number, product: HistoricalProductSearch) => {
        const margin = unitPrice - (product.buyingPrice ?? 0);
        const marginPercent = (margin / unitPrice * 100);
        const marginColor = marginPercent > 0 ? '#52c41a' : '#faad14';
        const maginPlusMinus =  marginPercent > 0 ? '+' : '';

        const marginValueString = (margin / 100).toFixed(2) + '€';
        const marginPercentString = maginPlusMinus + marginPercent.toFixed(2) + '%';

        return <>
          <SimplePrice unitPrice={unitPrice} conditioning={product.conditioning} conditioningQuantity={product.conditioningQuantity} unit={product.unitLabel} />
          {((product.buyingPrice ?? 0) > 0)
            &&
            <Tooltip
              placement="top"
               overlay={
              <div style={{ whiteSpace: 'pre-wrap' }}>
                Prix d&apos;achat : {Number((product.buyingPrice ?? 0) / 100).toFixed(2)} €
              </div>
            }>
                <Badge count={'Marge : ' + marginValueString + ' / ' + marginPercentString} color={marginColor} />
            </Tooltip>
          }
        </>
      }
    },
    {
      title:  t('cart.historicalProductSearchTableHeaderDocumentNumber'),
      dataIndex: 'documentNumber',
      key: 'documentNumber',
      align: 'center',
    },
    {
      title:  t('cart.historicalProductSearchTableHeaderDate'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      align: 'center',
      render: (createdAt: string) => {
        return <>
          {moment(createdAt).utc(true).format(localeDateFormat)}
        </>;
      },
    },
    {
      title: '',
      key: 'actions',
      align: 'center',
      dataIndex: 'id',
      render: (id: number, product: HistoricalProductSearch) => {
        return <>
          <Tooltip placement="top" overlay={'Afficher les offres'}>
            <Button
              onClick={() => handleOpenOffersModal(product.sku)}
              type={'primary'}
              className={styles.displayOfferButton}
               shape={'circle'} size={'large'} icon={<TagsOutlined />} />
          </Tooltip>

        </>
      }
    },


  ];

  return <div className={`${styles.container__table}`}>
    <Form name="customized_form_controls" layout="inline" onFinish={() => {return;}} initialValues={{
        price: {
          number: 0,
          currency: 'rmb',
        },
      }}>
      <Space style={{ marginBottom: 16, }}>
        <Form.Item name="sku" label="Produit / Code">
          <Input onChange={(e) => setProductCodeFilter(e.target.value)} />
        </Form.Item>
        <Form.Item name="documentNumber" label="N° du doc.">
          <Input onChange={(e) => setDocumentNumberFilter(e.target.value)} />
        </Form.Item>
        <Form.Item name="createdAt" label="Date">
          <RangePicker locale={locale}
                       popupClassName={'has-weeknumber'}
                       format={localeDateFormat}
                       onChange={onSelectDate}
          />
        </Form.Item>
        <Form.Item>
          <Button onClick={() => searchHistoricalProduct()} icon={<SearchOutlined />} type="primary" className={styles.displayOfferButton} />
        </Form.Item>
        <Form.Item>
          <Typography.Text className="ant-form-text" type="secondary">
            ( Max. 100 résultats )
          </Typography.Text>
        </Form.Item>
      </Space>
    </Form>
    <div className={styles.container}>
      <Table
        columns={tableColumnsOffers as Array<TableColumnDefinition<IHistoricalProductSearchRowKey>>}
        size="small"
        className={styles.container__table}
        rowClassName={styles.row}
        rowKey={(product) => product.id}
        loading={isLoading}
        pagination={false}
        dataSource={products}
        scroll={{
          y: 80 * 5,
        }}
      />
    </div>
  </div>
    ;
};
