import { useAppDispatch, useAppSelector } from 'store/store';
import { saveSavedCart } from 'store/savedCart';
import { sendMessage } from 'store/messages';
import { useNavigate } from 'react-router';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import React, { useMemo } from 'react';
import { useStepper } from 'hooks';
import { Button } from 'components/shared';
import { isFulfilled } from '@reduxjs/toolkit';
import { SaveOutlined } from '@ant-design/icons';
import { SavedCartStatusEnum } from '../../../store/savedCart/types';
import { selectPostalCode } from '../../../store/products';
import { selectTags, selectTcTags } from '../../../store/customers';
import { selectCartStatus, selectIsSavedCart, updateCartOffers } from '../../../store/cart';
import { getOrderPath } from '../../../constants/routes';
import env from '../../../config';
import styles from './SaveCartButton.module.scss';


const SaveCartButton: React.FC = () => {
  const dispatch = useAppDispatch();
  const { currentStep } = useStepper();
  const { t } = useTranslation();
  const isSavedCart = useSelector(selectIsSavedCart);
  const savedCartStatus = useSelector(selectCartStatus);
  const postalCode = useAppSelector(selectPostalCode);
  const tags = useAppSelector(selectTags);
  const tcTags = useAppSelector(selectTcTags);
  const navigate = useNavigate();

  const blockedActionStatus = useMemo(() => {
    if (!isSavedCart || !savedCartStatus) return false;
    if ([SavedCartStatusEnum.CONVERTED, SavedCartStatusEnum.REFUSED].includes(savedCartStatus)) return true;
  }, [savedCartStatus, isSavedCart]);

  const handleSaveCart = async () => {
    if (!currentStep) {
      return dispatch(sendMessage({ message: t('errors.invalidStepNumber'), type: 'error' }));
    }

    const stepNumber = currentStep.index + 1;

    // refresh cart Offers
    dispatch(updateCartOffers({
      postCode: postalCode?.postcode as string,
      tags: [...(tags ?? []), ...tcTags],
    }));

    const savedCart = await dispatch(saveSavedCart({ stepNumber, savePdfVariables: true }));
    if (isFulfilled(savedCart)) {
      navigate(getOrderPath(currentStep.route, savedCart.payload.cart.id));
    }
  };

  return (
    <>
      {env('enableSaveCart') && !blockedActionStatus && (
        <Button
          icon={<SaveOutlined />}
          onClick={handleSaveCart} shape={'circle'}
          className={styles.button} type="primary"
          size="large"
        />
      )}
    </>
  );
};

export default SaveCartButton;
