import { useParams } from 'react-router-dom';
import { FC } from 'react';
import { ValidatedContainer } from 'components/validated';
import { ValidatedType } from './Validated.types';

const Validated: FC = () => {
  const { type, cartId, frontCartUuid, frontCartId } = useParams();

  return (
    <ValidatedContainer
      type={type as ValidatedType}
      cartId={cartId}
      frontCartUuid={frontCartUuid}
      frontCartId={frontCartId ? Number(frontCartId) : undefined}
    />
  );
};

export default Validated;
