import { unwrap } from 'utils/api.utils';
import { handleCatchError, isFulfilledAction, isPendingAction, isRejectedAction } from 'utils';
import { IGroupCustomer } from 'types/groups';
import { FetchCartRequestAndCreateStateResponse } from 'types/cartRequest';
import { ErrorType, ICustomer, ICustomerGroup, ISearchedCustomer, IShippingAddresses, PaginatedResponse } from 'types';
import { RestoreSavedCartStateResponse } from 'store/savedCart/types';
import { restoreSavedCartMatcher } from 'store/savedCart/matcher';
import { RootState } from 'store/rootReducer';
import { IGetShippingAddressesParams } from 'store/delivery/types';
import { fetchShippingAddressesAdapter, fetchShippingAddressesStateAdapter } from 'store/delivery/adapters';
import {
  fetchCustomersNormalizer,
  fetchGroupCustomersNormalizer,
  setSelectedGroupCustomerNormalizer,
} from 'store/customers/normalizers';
import {
  fetchCustomersAdapter,
  fetchCustomersStateAdapter,
  fetchGroupCustomersAdapter,
  fetchGroupCustomersStateAdapter,
} from 'store/customers/adapters';
import { CUSTOMERS_REDUCER_NAME } from 'store/common';
import { fetchCartRequestAndCreateStateMatcher } from 'store/cartRequests/matcher';
import { CustomersApiService } from 'services/customersServiceApi';
import { AsyncThunk, PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  CustomersState,
  IFetchCustomersAvailableToWhitelist,
  IFetchCustomers,
  IFetchCustomersWhitelist,
  IFetchCustomersWhitelistByAtcCode,
  IFetchGroupCustomersWhitelist,
  RemoveCustomerFromWhitelistRequest,
  AddCustomerToWhitelistRequest,
} from './types';

const initialState: CustomersState = {
  isLoading: false,
  isGroup: false,
  whitelist: {
    filter: null,
    isLoading: false,
    total: 0,
    page: 1,
  },
  customerList: {
    filter: null,
    isLoading: false,
    total: 0,
    customers: [],
  },
  whitelistByAtcCode: {
    atcCode: '',
    isLoading: false,
    total: 0,
    page: 1,
    pageSize: 50,
  },
  groupWhitelist: {
    search: null,
    isLoading: false,
    total: 0,
    page: 1,
    data: [],
  },
  customers: {},
  groupAddress: {},
  groupTags: {},
  selectedCustomer: undefined,
  selectedGroupCustomers: {},
  selectedGroupId: undefined,
  error: null,
  selectedSearchedCustomer: undefined,
  selectedSearchedGroupCustomer: undefined,
  shippingAddresses: [],
};

export const getShippingAddresses: AsyncThunk<
  Array<IShippingAddresses>,
  IGetShippingAddressesParams,
  { rejectValue: ErrorType; state: RootState }
> = createAsyncThunk(
  `${CUSTOMERS_REDUCER_NAME}/getShippingAddresses`,
  async ({ userId, token }: IGetShippingAddressesParams, { rejectWithValue }) => {
    try {
      return await fetchShippingAddressesAdapter(fetchShippingAddressesStateAdapter({ userId, token }));
    } catch (err) {
      return handleCatchError(err, rejectWithValue);
    }
  },
);

export const fetchCustomers: AsyncThunk<
  Array<ICustomer>,
  IFetchCustomers,
  { rejectValue: ErrorType }
> = createAsyncThunk(
  `${CUSTOMERS_REDUCER_NAME}/fetchCustomers`,
  async ({ searchId, token }: IFetchCustomers, { rejectWithValue }) => {
    try {
      return await fetchCustomersAdapter(fetchCustomersStateAdapter({ searchId, token }));
    } catch (err) {
      return handleCatchError(err, rejectWithValue);
    }
  },
);

export const fetchCustomersWhitelist: AsyncThunk<
  PaginatedResponse<ICustomer>,
  IFetchCustomersWhitelist,
  { rejectValue: ErrorType }
> = createAsyncThunk(
  `${CUSTOMERS_REDUCER_NAME}/fetchCustomersWhitelist`,
  async ({ token, skip, limit, filter }: IFetchCustomersWhitelist, { rejectWithValue }) => {
    try {
      return unwrap(await CustomersApiService.getCustomersWhitelist(token, skip, limit, filter));
    } catch (err) {
      return handleCatchError(err, rejectWithValue);
    }
  },
);

export const fetchCustomersAvailableToWhitelist: AsyncThunk<
  PaginatedResponse<ICustomer>,
  IFetchCustomersAvailableToWhitelist,
  { rejectValue: ErrorType }
> = createAsyncThunk(
  `${CUSTOMERS_REDUCER_NAME}/fetchCustomersAvailableToWhitelist`,
  async ({ filter, token, atcCode }: IFetchCustomersAvailableToWhitelist, { rejectWithValue }) => {
    try {
      return unwrap(await CustomersApiService.getCustomersAvailableToWhitelist(filter as string, token, atcCode));
    } catch (err) {
      return handleCatchError(err, rejectWithValue);
    }
  },
);

export const fetchCustomersWhitelistByAtcCode: AsyncThunk<
  PaginatedResponse<ICustomer>,
  IFetchCustomersWhitelistByAtcCode,
  { rejectValue: ErrorType }
> = createAsyncThunk(
  `${CUSTOMERS_REDUCER_NAME}/fetchCustomersWhitelistByAtcCode`,
  async ({ token, skip, pageSize, atcCode }: IFetchCustomersWhitelistByAtcCode, { rejectWithValue }) => {
    try {
      return unwrap(await CustomersApiService.getCustomersWhitelistByAtcCode(token, skip, pageSize, atcCode));
    } catch (err) {
      return handleCatchError(err, rejectWithValue);
    }
  },
);

export const removeCustomerFromWhitelist: AsyncThunk<
  PaginatedResponse<ICustomer>,
  RemoveCustomerFromWhitelistRequest,
  { rejectValue: ErrorType }
> = createAsyncThunk(
  `${CUSTOMERS_REDUCER_NAME}/removeFromWhitelist`,
  async ({ customerIdentifier, atcCode }, { rejectWithValue }) => {
    try {
      return unwrap(await CustomersApiService.removeFromWhitelist(customerIdentifier, atcCode));
    } catch (err) {
      return handleCatchError(err, rejectWithValue);
    }
  },
);

export const addCustomerToWhitelist: AsyncThunk<
  PaginatedResponse<ICustomer>,
  AddCustomerToWhitelistRequest,
  { rejectValue: ErrorType }
> = createAsyncThunk(
  `${CUSTOMERS_REDUCER_NAME}/addToWhitelist`,
  async ({ customerIdentifier, atcCode }, { rejectWithValue }) => {
    try {
      return unwrap(await CustomersApiService.addToWhitelist(customerIdentifier, atcCode));
    } catch (err) {
      return handleCatchError(err, rejectWithValue);
    }
  },
);

export const fetchGroupCustomers: AsyncThunk<
  Array<IGroupCustomer>,
  IFetchCustomers,
  { rejectValue: ErrorType }
> = createAsyncThunk(
  `${CUSTOMERS_REDUCER_NAME}/fetchGroupCustomers`,
  async ({ searchId, token }: IFetchCustomers, { rejectWithValue }) => {
    try {
      return await fetchGroupCustomersAdapter(fetchGroupCustomersStateAdapter({ searchId, token }));
    } catch (err) {
      return handleCatchError(err, rejectWithValue);
    }
  },
);

export const fetchGroupCustomersWhitelist: AsyncThunk<
  PaginatedResponse<IGroupCustomer>,
  IFetchGroupCustomersWhitelist,
  { rejectValue: ErrorType }
> = createAsyncThunk(
  `${CUSTOMERS_REDUCER_NAME}/fetchGroupCustomersWhitelist`,
  async ({ token, skip, limit, search }: IFetchGroupCustomersWhitelist, { rejectWithValue }) => {
    try {
      return unwrap(await CustomersApiService.getGroupCustomersWhitelist(token, skip, limit, search));
    } catch (err) {
      return handleCatchError(err, rejectWithValue);
    }
  },
);

const clearState = (state: CustomersState) => {
  state.customers = initialState.customers;
  state.selectedCustomer = initialState.selectedCustomer;
  state.selectedSearchedCustomer = initialState.selectedSearchedCustomer;
  state.selectedSearchedGroupCustomer = initialState.selectedSearchedGroupCustomer;
  state.selectedGroupCustomers = initialState.selectedGroupCustomers;
  state.shippingAddresses = initialState.shippingAddresses;
  state.whitelist = initialState.whitelist;
  state.whitelistByAtcCode = initialState.whitelistByAtcCode;
  state.groupWhitelist = initialState.groupWhitelist;
};

const customersSlice = createSlice({
  name: CUSTOMERS_REDUCER_NAME,
  initialState,
  reducers: {
    clearCustomersState() {
      return { ...initialState };
    },
    setCustomersView(state) {
      state.isGroup = false;
      clearState(state);
    },
    setCustomersGroupsView(state) {
      state.isGroup = true;
      clearState(state);
    },
    setSelectedCustomer(state, { payload: id }: PayloadAction<number>) {
      state.selectedCustomer = id;
    },
    clearPreviousGroupSelection(state) {
      state.selectedGroupId = initialState.selectedGroupId;
      state.selectedGroupCustomers = initialState.selectedGroupCustomers;
    },
    setSelectedGroupCustomer(state, { payload: groupCustomer }: PayloadAction<ICustomerGroup>) {
      const { key, value } = setSelectedGroupCustomerNormalizer(groupCustomer);
      state.selectedGroupCustomers[key] = value;
    },
    clearSelectedCustomer(state) {
      state.selectedCustomer = undefined;
    },
    clearSelectedGroupCustomer(state, { payload: { id, groupId } }: PayloadAction<{ id: number; groupId: number }>) {
      delete state.selectedGroupCustomers[`${id}-${groupId}`];
      if (Object.keys(state.selectedGroupCustomers).length === 0) {
        state.selectedGroupId = initialState.selectedGroupId;
      }
    },
    clearSelectedGroupCustomerState(state) {
      state.selectedGroupCustomers = initialState.selectedGroupCustomers;
      state.selectedGroupId = initialState.selectedGroupId;
    },
    setSelectedSearchedCustomer(state, { payload: customer }: PayloadAction<ISearchedCustomer | undefined>) {
      state.selectedSearchedCustomer = customer;
    },

    setSelectedSearchedGroupCustomer(state, { payload: customer }: PayloadAction<ISearchedCustomer | undefined>) {
      state.selectedGroupCustomers = initialState.selectedGroupCustomers;
      state.selectedSearchedGroupCustomer = customer;
    },
    setSearchedGroupCustomer(state, { payload: customer }: PayloadAction<ISearchedCustomer | undefined>) {
      state.selectedSearchedGroupCustomer = customer;
    },
    setSelectedGroupId(state, { payload: id }: PayloadAction<number | undefined>) {
      state.selectedGroupId = id;
    },
    removeSelectedGroupId(state) {
      state.selectedGroupId = undefined;
    },
    setWhitelistPage(state, { payload: page }: PayloadAction<number>) {
      state.whitelist.page = page;
    },
    setWhitelistFilter(state, { payload: filter }: PayloadAction<string | null>) {
      state.whitelist.filter = filter;
    },
    setCustomerListFilter(state, { payload: filter }: PayloadAction<string | null>) {
      state.customerList.filter = filter;
    },
    setWhitelistByAtcCodeAtcCode(state, { payload: atcCode }: PayloadAction<string>) {
      state.whitelistByAtcCode.atcCode = atcCode;
    },
    setWhitelistByAtcCodePage(
      state,
      { payload: { page, pageSize } }: PayloadAction<{ page: number; pageSize: number }>,
    ) {
      state.whitelistByAtcCode.page = page;
      state.whitelistByAtcCode.pageSize = pageSize;
    },
    setWhitelistByAtcCodeTotal(state, { payload: total }: PayloadAction<number>) {
      state.whitelistByAtcCode.total = total;
    },
    setGroupWhitelistPage(state, { payload: page }: PayloadAction<number>) {
      state.groupWhitelist.page = page;
    },
    setGroupWhitelistFilter(state, { payload: search }: PayloadAction<string | null>) {
      state.groupWhitelist.search = search;
    },
    setSelectedGroup(state, { payload }: PayloadAction<Array<IGroupCustomer>>) {
      const { customers, groupAddress, groupTags } = fetchGroupCustomersNormalizer(payload);

      state.customers = customers;
      state.groupAddress = groupAddress;
      state.groupTags = groupTags;
    },
    clearShippingAddresses(state) {
      state.shippingAddresses = [...initialState.shippingAddresses];
    },
    clearWhitelist(state) {
      state.whitelist.filter = null;
      state.whitelist.isLoading = false;
      state.whitelist.total = 0;
      state.whitelist.page = 1;
      state.customers = initialState.customers;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCustomersWhitelist.fulfilled, (state, { payload }: PayloadAction<PaginatedResponse<ICustomer>>) => {
        const { data, total } = payload;
        const { customers } = fetchCustomersNormalizer(data);
        state.whitelist.isLoading = false;
        state.whitelist.total = total;
        state.customers = customers;
      })
      .addCase(fetchCustomersWhitelist.rejected, (state) => {
        state.whitelist.isLoading = false;
        state.whitelist.total = 0;
        state.whitelist.page = 1;
        state.customers = initialState.customers;
      })
      .addCase(fetchCustomersWhitelist.pending, (state) => {
        state.whitelist.isLoading = true;
      })
      .addCase(fetchCustomersAvailableToWhitelist.pending, (state) => {
        state.customerList.isLoading = true;
      })
      .addCase(
        fetchCustomersAvailableToWhitelist.fulfilled,
        (state, { payload }: PayloadAction<PaginatedResponse<ICustomer>>) => {
          const { data, total } = payload;
          state.customerList.isLoading = false;
          state.customerList.total = total;
          state.customerList.customers = data;
        },
      )
      .addCase(fetchCustomersAvailableToWhitelist.rejected, (state) => {
        state.customerList.isLoading = false;
        state.customerList.total = 0;
        state.customerList.customers = initialState.customerList.customers;
      })
      .addCase(
        fetchCustomersWhitelistByAtcCode.fulfilled,
        (state, { payload }: PayloadAction<PaginatedResponse<ICustomer>>) => {
          const { data, total } = payload;
          const { customers } = fetchCustomersNormalizer(data);
          state.whitelistByAtcCode.isLoading = false;
          state.whitelistByAtcCode.total = total;
          state.customers = customers;
        },
      )
      .addCase(fetchCustomersWhitelistByAtcCode.rejected, (state) => {
        state.whitelistByAtcCode.isLoading = false;
        state.whitelistByAtcCode.total = 0;
        state.whitelistByAtcCode.page = 1;
        state.customers = initialState.customers;
      })
      .addCase(fetchCustomersWhitelistByAtcCode.pending, (state) => {
        state.whitelistByAtcCode.isLoading = true;
      })
      .addCase(fetchCustomers.fulfilled, (state, { payload }: PayloadAction<Array<ICustomer>>) => {
        const { customers } = fetchCustomersNormalizer(payload);
        state.customers = customers;
      })

      .addCase(
        removeCustomerFromWhitelist.fulfilled,
        (state, { payload }: PayloadAction<PaginatedResponse<ICustomer>>) => {
          const { data, total } = payload;
          const { customers } = fetchCustomersNormalizer(data);
          state.whitelistByAtcCode.isLoading = false;
          state.whitelistByAtcCode.total = total;
          state.customers = customers;
        },
      )
      .addCase(addCustomerToWhitelist.fulfilled, (state, { payload }: PayloadAction<PaginatedResponse<ICustomer>>) => {
        const { data, total } = payload;
        const { customers } = fetchCustomersNormalizer(data);
        state.whitelistByAtcCode.isLoading = false;
        state.whitelistByAtcCode.total = total;
        state.customers = customers;
      })

      .addCase(
        fetchGroupCustomersWhitelist.fulfilled,
        (state, { payload }: PayloadAction<PaginatedResponse<IGroupCustomer>>) => {
          const { data, total } = payload;
          state.groupWhitelist.isLoading = false;
          state.groupWhitelist.total = total;
          state.groupWhitelist.data = data;
        },
      )
      .addCase(fetchGroupCustomersWhitelist.rejected, (state) => {
        state.groupWhitelist.isLoading = false;
        state.groupWhitelist.total = 0;
        state.groupWhitelist.page = 1;
        state.groupWhitelist.data = [];
      })
      .addCase(fetchGroupCustomersWhitelist.pending, (state) => {
        state.groupWhitelist.isLoading = true;
      })
      .addCase(fetchGroupCustomers.fulfilled, (state, { payload }: PayloadAction<Array<IGroupCustomer>>) => {
        const { customers, groupAddress, groupTags } = fetchGroupCustomersNormalizer(payload);

        state.customers = customers;
        state.groupAddress = groupAddress;
        state.groupTags = groupTags;
      })
      .addCase(getShippingAddresses.fulfilled, (state, { payload }: PayloadAction<Array<IShippingAddresses>>) => {
        state.shippingAddresses = payload;
      })
      .addCase(getShippingAddresses.rejected, (state) => {
        state.shippingAddresses = [];
      })
      .addMatcher(isPendingAction(`${CUSTOMERS_REDUCER_NAME}/`), (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addMatcher(isRejectedAction(`${CUSTOMERS_REDUCER_NAME}/`), (state, { payload }) => {
        state.isLoading = false;
        state.error = payload;
      })
      .addMatcher(isFulfilledAction(`${CUSTOMERS_REDUCER_NAME}/`), (state) => {
        state.isLoading = false;
        state.error = null;
      });

    builder.addMatcher(
      restoreSavedCartMatcher(),
      (state, { payload }: PayloadAction<RestoreSavedCartStateResponse>) => {
        Object.assign<CustomersState, CustomersState>(state, initialState);

        const {
          state: { customers },
        } = payload;

        Object.assign<CustomersState, RestoreSavedCartStateResponse['state']['customers']>(state, customers);
      },
    );

    builder.addMatcher(
      fetchCartRequestAndCreateStateMatcher(),
      (state, { payload }: PayloadAction<FetchCartRequestAndCreateStateResponse>) => {
        const {
          cartRequestState: { customer },
        } = payload;

        Object.assign<CustomersState, CustomersState>(state, initialState);
        Object.assign<CustomersState, FetchCartRequestAndCreateStateResponse['cartRequestState']['customer']>(
          state,
          customer,
        );
      },
    );
  },
});

export const {
  clearCustomersState,
  setSelectedCustomer,
  setCustomersGroupsView,
  setCustomersView,
  setSelectedGroupCustomer,
  clearSelectedGroupCustomer,
  clearSelectedGroupCustomerState,
  setSelectedSearchedCustomer,
  setSelectedSearchedGroupCustomer,
  setSearchedGroupCustomer,
  setSelectedGroupId,
  removeSelectedGroupId,
  setWhitelistPage,
  setWhitelistFilter,
  setCustomerListFilter,
  setWhitelistByAtcCodeAtcCode,
  setWhitelistByAtcCodePage,
  setGroupWhitelistPage,
  setGroupWhitelistFilter,
  setSelectedGroup,
  clearPreviousGroupSelection,
  clearShippingAddresses,
  clearWhitelist,
} = customersSlice.actions;

export default customersSlice.reducer;
