import { useTheme } from 'hooks/useTheme';
import { DiscountType } from '../Discount/Discount.types';
import { priceFormatting } from '../../../utils';
import { DiscountToolTipPriceProps, PriceProps } from './Price.types';
import styles from './Price.module.scss';


const Price = (props: PriceProps): JSX.Element => {
  const { unitPrice, className, unit, type } = props;
  const themeStyle = useTheme(styles);

  return (
    <span className={`${themeStyle('price')} ${className}`}>
      <span>{priceFormatting(unitPrice).price} </span>
      {type === 'PERCENTAGE' ? (
        <span className={styles.decimals}>{priceFormatting(unitPrice).decimals} %</span>
      ) : (
        <span className={styles.decimals}>
          €{priceFormatting(unitPrice).decimals}
          <span className={styles.unit}>{unit ? ` / ${unit}` : ''}</span>
        </span>
      )}
    </span>
  );
};

export const PriceDiscountToolTip = (props: DiscountToolTipPriceProps) => {
  const { discountedPrice, discount,  className, unit, type } = props;

  const symbolType = (dt: DiscountType | undefined) => {
    return dt === DiscountType.PERCENTAGE ? '%' : '€';
  };

  const discountDisplay = (discountValue: number, discountType: DiscountType)  => {
    let discount = discountValue ? discountValue / 100 : 0;
    const symbol = symbolType(discountType);

    if (discountValue) {
      discount = discountValue / 100;
    }
    if (discount !== 0) {
      return (
        <span className={`${styles.price} ${styles.price_discount}`}>
          {discount} {symbol}
        </span>
      );
    }
  }

  return (
    <div className={`${styles.block} ${className}`}>
      {discountDisplay(discount, type as DiscountType)}
       <Price unitPrice={discountedPrice} />
    </div>
  );
};

export default Price;
