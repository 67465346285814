import { getParsedProductsForTable, getProductReplacements } from 'utils';
import { Supplier } from 'types/suppliers';
import { MultiSearchHeader, Unit } from 'types/product';
import { ErrorType, INormalizedList, ITown } from 'types';
import { RootState } from '../rootReducer';
import { DisplayOfferGroup } from '../../types/offer';
import {
  CustomerQuotationSearchResult,
  IDeparture,
  IParsedProductItem,
  IParsedProductItemRowKey,
  IWarehouseStockDataItem,
  MultiSearchResult,
} from './types';

export const selectTableMinimizeState = (state: RootState): boolean => state.products.tableMinimized;

export const selectProductsError = (state: RootState): ErrorType | null => state.products.productError;

export const selectPostcodeError = (state: RootState): ErrorType | null => state.products.postcodeError;

export const selectIsLoading = (state: RootState): boolean => state.products.isLoading;

export const selectIsLoadingPostalCode = (state: RootState): boolean => state.products.isLoadingPostalCode;

export const offersFirstSort = (a: IParsedProductItem, b: IParsedProductItem): number =>
  !!a.offerId && !b.offerId ? -1 : 1;
export const productIdsAscSort = (a: IParsedProductItem, b: IParsedProductItem): number =>
  a.productId > b.productId ? 1 : -1;

export const generateList = (
  searchedVariantsIds: number[],
  productsList: Array<IParsedProductItem>,
  excludeNoStockOffers?: boolean,
): Array<IParsedProductItemRowKey> => {
  const list: Array<{ product: IParsedProductItemRowKey; replacements: Array<IParsedProductItemRowKey> }> = [];

  searchedVariantsIds?.forEach((variantId) => {
    const productsFiltered = productsList.filter((curr) => curr.variantId === variantId);

    if (!productsFiltered) {
      throw new Error('The product variant should be in the list.');
    }

    productsFiltered.forEach((product) => {
      const rowKey = `${product.productId}-${product.variantId}-${product.offerId}`;
      const offerOnline = product.offer?.Online;

      const parsedReplacements = Object.values(getProductReplacements(product))
        .reduce<Array<IParsedProductItem>>((accum, item) => {
          const items = productsList.filter(
            (curr) => curr.productId === item.replacement.productId && curr.productId !== product.productId,
          );

          const result: any = [];
          items.forEach((currentItem) => {
            if (excludeNoStockOffers && !currentItem?.offer) {
              return;
            }

            result.push({
              ...currentItem,
              replacement: {
                type: item.type,
                name: item.replacement.productName,
                productId: item.replacement.productId,
                variantId: product.variantId,
              },
              publicComment: item.replacement.publicComment,
              privateComment: item.replacement.privateComment,
            });
          });

          return [...accum, ...result];
        }, [])
        .sort(productIdsAscSort)
        .sort(offersFirstSort);

      const replacements: Array<IParsedProductItemRowKey> = parsedReplacements.map((current) => {
        return {
          ...current,
          rowKey: `${rowKey}::${current.replacement?.type || 'none'}::${current.productId}-${current.variantId}-${
            current.offerId
          }`,
        };
      });

      if (excludeNoStockOffers && replacements?.length === 0 && !product.offer) {
        return;
      }

      list.push({
        product: {
          ...product,
          rowKey,
          offerOnline: offerOnline,
        },
        replacements,
      });
    });
  });

  const sortedList = list.sort((a, b) => a.product.sortingIndex - b.product.sortingIndex);
  return [
    // enabled products
//    ...sortedList.filter((p) => !p.product.disabled && !p.product.ignoreStock),
//    ...sortedList.filter((p) => !p.product.disabled && p.product.ignoreStock),
    ...sortedList.filter((p) => !p.product.disabled),
    // disabled products with replacements
    ...sortedList.filter((p) => p.product.disabled && p.replacements.length > 0),
    // disabled products without replacements
    ...sortedList.filter((p) => p.product.disabled && p.replacements.length === 0),
  ].reduce<Array<IParsedProductItemRowKey>>((acc, item) => {
    return [...acc, item.product, ...item.replacements];
  }, []);
};

export const selectParsedProductsForTable =
  () =>
  (state: RootState): Array<IParsedProductItemRowKey> => {
    const { products, searchedVariantsIds, offers, excludeNoStockOffers } = state.products;

    const productsList = getParsedProductsForTable({
      products,
      offers,
    });

    /**
     * Sort by substitution
     */
    return generateList(searchedVariantsIds, productsList, excludeNoStockOffers);
  };

export const selectParsedReplacementProducts =
  () =>
  (state: RootState): Array<IParsedProductItemRowKey> => {
    const { products, searchedVariantIds, offers } = state.products.replacementModal;
    const { excludeNoStockOffers } = state.products;

    const productsList = getParsedProductsForTable({
      products,
      offers,
    });

    /**
     * Sort by substitution
     */
    return generateList(searchedVariantIds, productsList, excludeNoStockOffers);
  };

export const selectPostalCode = (state: RootState): ITown | null => state.products.postalCodes;

export const selectFetchedTowns = (state: RootState): Array<ITown> => state.products.fetchedTowns;

export const selectMultiSearchQueries = (state: RootState): Array<MultiSearchHeader> =>
  state.products.multiSearch.queries;
export const selectMultiSearchResults = (state: RootState): Array<MultiSearchResult> =>
  state.products.multiSearch.searchResults;
export const selectCustomerQuotationSearchResults = (
  state: RootState,
): INormalizedList<CustomerQuotationSearchResult> => state.products.customerQuotation.searchResults;

export const selectIsClientMode = (state: RootState): boolean => state.products.isClientMode;
export const selectIsCustomerMode = (state: RootState): boolean => !state.products.isClientMode;

export const selectSuppliers = (state: RootState): Array<Supplier> => state.products.suppliers;
export const selectIsLoadingSuppliers = (state: RootState): boolean => state.products.isLoadingSuppliers;
export const selectWarehouseStockSku = (state: RootState): string | null => state.products.warehouseStocks.sku;
export const selectWarehouseName = (state: RootState): string | null => state.products.warehouseStocks.warehouseName;
export const selectWarehouseRememberName = (state: RootState): boolean =>
  state.products.warehouseStocks.rememberWarehouse;
export const selectWarehouseStockVisibility = (state: RootState): boolean => state.products.warehouseStocks.visibility;
export const selectWarehouseStockLoading = (state: RootState): boolean => state.products.warehouseStocks.isLoading;
export const selectDisplayAllOffers = (state: RootState): boolean => state.products.displayAllOffers;
export const selectExcludeNoStockOffers = (state: RootState): boolean => state.products.excludeNoStockOffers;
export const selectOfferGroupBy = (state: RootState): DisplayOfferGroup => state.products.displayOfferGroup;

export const selectWarehouseStockData = (state: RootState): IWarehouseStockDataItem[] =>
  state.products.warehouseStocks.data[state.products.warehouseStocks.sku] ?? ([] as IWarehouseStockDataItem[]);
export const selectDepartures = (state: RootState): Record<number, IDeparture> => state.products.departures;

export const selectReplacementModalVisible = (state: RootState): boolean => state.products.replacementModal.visible;
export const selectReplacementModalSku = (state: RootState): string | null => state.products.replacementModal.sku;

export const selectReplacementModalMode = (state: RootState): string | null => state.products.replacementModal.mode;

export const selectReplacementModalProductIndex = (state: RootState): number | null =>
  state.products.replacementModal.productIndex;
export const selectReplacementModalDisplayAllOffers = (state: RootState): boolean =>
  state.products.replacementModal.displayAllOffers;
export const selectReplacementModalQuantity = (state: RootState): number => state.products.replacementModal.quantity;
export const selectReplacementModalLoading = (state: RootState): boolean => state.products.replacementModal.isLoading;

export const selectAddProductModalVisible = (state: RootState): boolean => state.products.addProductModal.visible;
export const selectAddProductModalLoading = (state: RootState): boolean => state.products.addProductModal.isLoading;
export const selectAddProductModalUnits = (state: RootState): Unit[] => state.products.addProductModal.units;
