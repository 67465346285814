/* eslint-disable no-console */
import { Logger, LoggerJSON, LoggerOptions, LoggerParser, LoggerParserInputOutput, LoggerType } from 'types';
import { merge } from 'lodash';
import env from 'config';

const initialOptions: LoggerOptions = {
  enabled: true,
  prefix: true,
  json: LoggerJSON.NONE,
};

const createLogger = (loggerType: LoggerType, argOptions: Partial<LoggerOptions> = initialOptions): Logger => {
  const options = merge(initialOptions, argOptions);

  if (!options.enabled) {
    return {
      write: (..._args: unknown[]) => null,
    };
  }

  /**
   * Usage:
   *
   * WIll write the string content to the terminal
   * logger.write('string')
   *
   * If the callback return a string, array of strings, will write that value to the console. In this way you can proccess the data in the callback function
   * logger.write(['a', 'b'], (data, output, type) => {
   *    return data.join('\n')
   * });
   *
   * If the callback return undefined use the output method to write the log on the console
   * logger.write({}, (data, output, type) => {
   *    output(type, 'test123');
   * });
   */
  function write(data: LoggerParserInputOutput): void | null;
  function write<T>(data: T, parser?: LoggerParser<T>): void | null;
  function write<T>(data: T | string, parser?: LoggerParser<T>): void | null {
    let prefix = options.prefix;
    let buffer: LoggerParserInputOutput[] = [];
    let output: LoggerParserInputOutput = '';

    if (parser) {
      const parserOutput = parser(
        data as T,
        (...outputData) => {
          if (options.json !== LoggerJSON.NONE) {
            // Print objects and array as JSON string
            outputData
              .filter((item) => typeof item === 'object')
              .forEach((item) => {
                let jsonFormat: string | number | undefined = undefined;
                switch (options.json) {
                  case LoggerJSON.PRETTY:
                    jsonFormat = '\t';
                    break;
                  case LoggerJSON.MINIMIZE:
                  default:
                    jsonFormat = undefined;
                    break;
                }
                console.log(JSON.stringify(item, null, jsonFormat));
              });
          }
        },
        `${loggerType} ::`,
      );
      //  Don't write the output here if the methods return's undefined, will print the output directly there
      if (typeof parserOutput === 'undefined') {
        return;
      }

      // Disable prefix for this case. It can be prefixed in the callback if needed
      prefix = false;

      output = Array.isArray(parserOutput) ? parserOutput.join('\n') : parserOutput;
    } else {
      output = data as LoggerParserInputOutput;
    }

    if (prefix) {
      buffer = [loggerType, ' :: ', ...buffer, output];
    } else {
      buffer = [...buffer, output];
    }

    console.log(buffer.filter((item) => String(item).length > 0).join(''));
  }

  return {
    write,
  };
};

/**
 * Initialize the loggers
 */
const loggers: Record<LoggerType, Logger> = {
  ALGOLIA: createLogger(LoggerType.ALGOLIA, {
    enabled: env('enableAlgoliaLog'),
    json: LoggerJSON.MINIMIZE,
  }),
};

export const getLogger = (loggerType: LoggerType): Logger => {
  return loggers[loggerType];
};

export const AlgoliaLogger = getLogger(LoggerType.ALGOLIA);

export default getLogger;
