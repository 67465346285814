import { FC, useState } from 'react';
import { Modal } from 'components/shared';
import passwordIcon from 'assets/icons/password_icon.svg';
import ResetPasswordForm from './components/ResetPasswordForm';
import ForgotPasswordForm from './components/ForgotPasswordForm';
import { IForgotPasswordModalProps } from './ForgotPasswordModal.type';
import styles from './ForgotPasswordModal.module.scss';

const ForgotPasswordModal: FC<IForgotPasswordModalProps> = (props: IForgotPasswordModalProps) => {
  const { visible, onClose } = props;

  const [showResetPassword, setShowResetPassword] = useState(false);

  const onCancel = () => {
    setShowResetPassword(false);
    onClose();
  };

  return (
    <Modal
      open={visible}
      onCancel={onCancel}
      className={styles.modal}

    >
      <div className={styles.container}>
        {showResetPassword ? (
          <ResetPasswordForm onClose={onCancel} />
        ) : (
          <ForgotPasswordForm onClose={() => setShowResetPassword(true)} />
        )}
        <p>&nbsp;</p>
      </div>
    </Modal>
  );
};

export default ForgotPasswordModal;
