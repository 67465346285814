import { useDispatch, useSelector } from 'react-redux';
import React, { useMemo } from 'react';
import moment from 'moment';
import i18n from 'i18next';
import { Image, Select } from 'antd';
import { selectLanguage, setLanguage } from '../../../store/settings';
import { flags, getLanguagesList, Language, languages as allLanguages } from '../../../locales';
import { LanguageSelectorProps } from './LanguageSelector.types';

const LanguageSelector: React.FC<LanguageSelectorProps> = (props: LanguageSelectorProps) => {
  const { className = '' } = props;

  const dispatch = useDispatch();
  const selectedLanguage = useSelector(selectLanguage);
  moment.updateLocale(selectedLanguage, {
    week: {
      dow: 1,
    },
  });
  const languages = useMemo(() => getLanguagesList(), []);
  const renderOptions = () => {
    return languages.map((lang) => ({
      label: allLanguages[lang as Language],
      value: lang as Language,
      icon: (
        <Image key={lang} src={flags[lang as Language]} preview={false} height={30} width={30} className={'image'} />
      ),
    }));
  };

  const handleLanguageChange = async (selectedLang: Language) => {
    moment.updateLocale(selectedLang, {
      week: {
        dow: 1,
      },
    });
    await i18n.changeLanguage(selectedLang);
    dispatch(setLanguage(selectedLang));
  };

  return (
    <Select className={className} defaultValue={selectedLanguage} onChange={handleLanguageChange}>
      {renderOptions().map((option) => (
        <Select.Option key={option.value} value={option.value} label={option.label}>
          {option.icon}
        </Select.Option>
      ))}
    </Select>
  );
};

export default LanguageSelector;
