/* eslint-disable @typescript-eslint/no-unused-vars */
import { isEmpty } from 'utils';
import { clearUserState, fetchAtcUser, setLoggedUser, setUser } from 'store/user';
import { clearSettingsState } from 'store/settings';
import { clearSavedCartState } from 'store/savedCart';
import { clearProductsState } from 'store/products';
import { clearOrderHistory } from 'store/ordersHistory';
import { clearMessagesState } from 'store/messages';
import { clearDeliveryState } from 'store/delivery';
import { clearCustomersState } from 'store/customers';
import { clearCartRequestsState } from 'store/cartRequests';
import { clearCartState } from 'store/cart';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { FC, useCallback, useState } from 'react';
import { useTheme } from 'hooks/useTheme';
import { LoginResponse } from 'hooks/useAuth.types';
import { useAuth } from 'hooks/useAuth';
import { useLogout } from 'hooks';
import { Button, FormInput, Text } from 'components/shared';
import ADButton from 'antd/lib/button';
import { Form, notification } from 'antd';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import NewPasswordForm from '../NewPasswordForm';
import { clearHistoricalProductSearchState } from '../../../store/historicalProductSearch/reducer';
import { emailRule, passwordRule } from './LoginForm.validators';
import { ILoginData, IResponseError, Props } from './LoginForm.types';
import styles from './LoginForm.module.scss';

const LogInForm: FC<Props> = (props: Props) => {
  const { onClickForgotPassword } = props;
  const [loading, setLoading] = useState(false);
  const [loginNewPasswordResponse, setLogInNewPasswordResponse] = useState<LoginResponse>();
  const [isNewUser, setIsNewUser] = useState(false);
  const dispatch = useDispatch();
  const { loginRedirectAfterLogout } = useLogout();
  const { login } = useAuth();
  const style = useTheme(styles);
  const { t } = useTranslation();

  const clearState = useCallback(() => {
    dispatch(clearUserState());
    dispatch(clearProductsState());
    dispatch(clearHistoricalProductSearchState());
    dispatch(clearOrderHistory());
    dispatch(clearMessagesState());
    dispatch(clearDeliveryState());
    dispatch(clearCartState());
    dispatch(clearCartRequestsState());
    dispatch(clearSavedCartState());
    dispatch(clearCartRequestsState);
    dispatch(clearCustomersState());
    dispatch(clearSettingsState());
  }, [dispatch]);

  const openErrorNotification = (error: IResponseError[] | IResponseError) => {
    if (Array.isArray(error)) {
      error.forEach((err: IResponseError) => {
        notification.open({
          message: 'Error',
          description: err.message,
        });
      });
    } else {
      notification.open({
        message: 'Error',
        description: error.message,
      });
    }
  };
  const onFinish = async (payload: ILoginData) => {
    clearState();
    setLoading(true);

    try {
      const response = await login(payload.username, payload.password);
      setLoading(false);
      if (response.ChallengeName === 'NEW_PASSWORD_REQUIRED') {
        setLogInNewPasswordResponse({ ...response });
        setIsNewUser(true);
      }

      if (response?.User && !isEmpty(response?.AuthenticationResult)) {
        dispatch(setLoggedUser(response?.User?.email));
        dispatch(setUser(response.User));
        loginRedirectAfterLogout();
        if (response?.User?.email) {
          dispatch(
            fetchAtcUser({
              token: response?.AuthenticationResult?.IdToken,
            }),
          );
        }
      }
    } catch (error) {
      openErrorNotification(error as unknown as IResponseError[]);
      setLoading(false);
    }
  };

  return (
    <div className={styles.login}>
      {isNewUser && loginNewPasswordResponse ? (
        <NewPasswordForm user={loginNewPasswordResponse} backToLoginPage={() => setIsNewUser(false)} />
      ) : (
        <>
          <Form name="login" className={styles.login__form} onFinish={onFinish}>
            <FormInput
              name="username"
              rules={emailRule}
              inputProps={{
                placeholder: t('login.email')!,
                prefix: <UserOutlined />,
              }}
            />
            <FormInput
              name="password"
              rules={passwordRule}
              inputProps={{
                placeholder: t('login.password')!,
                prefix: <LockOutlined />,
                color: '#00dea2',
                type: 'password',
              }}
            />

            <div className={styles.login__form_forgotPassLabel}>
              <Text type="secondary-gray1">{t('login.forgotPassword')}</Text>
              <ADButton type="text" className={styles.btn} onClick={onClickForgotPassword}>
                <Text type="secondary-primary">{t('login.clickHere')}</Text>
              </ADButton>
            </div>

            <Form.Item>
              <Button className={style('button')} loading={loading} type="primary" htmlType="submit">
                {t('login.login')}
              </Button>
            </Form.Item>
          </Form>
        </>
      )}
    </div>
  );
};

export default LogInForm;
