export const users_fr = {
  pageTitle: 'Utilisateurs',
  edit: 'Modifier',
  whitelist: 'Portefeuille',
  delete: 'Supprimer',
  add: 'Ajouter',
  confirmDelete: 'Êtes-vous sûr de vouloir supprimer cet utilisateur ?',
  confirmAddToWhitelist: 'Êtes-vous sûr de vouloir ajouter ce tiers ?',
  confirmDeleteFromWhitelist: 'Êtes-vous sûr de vouloir supprimer ce tiers ?',
  successAddToWhitelist: 'Tiers ajouté avec succès',
  successDeleteFromWhitelist: 'Tiers supprimé avec succès',
  groups: 'Groupes',
  email: 'Email',
  customerIdentifier: 'Code tiers',
  actions: 'Actions',
  nextPage: 'Page suivante',
  prevPage: 'Page précédente',
  newUserPageTitle: 'Nouvel Utilisateur',
  editUserPageTitle: 'Modifier Utilisateur',
  editCustomerWhitelistPageTitle: 'Modifier Portefeuille',
  editCustomerWhitelistExisting: 'Tiers existants',
  editCustomerWhitelistAdd: 'Ajouter un tiers',
  personalInformation: 'Informations Personnelles',
  fullname: 'Nom complet',
  lastname: 'Nom de famille',
  firstname: 'Prénom',
  username: "Nom d'utilisateur",
  phoneNumber: 'Numéro de téléphone',
  saveChanges: 'Enregistrer',
  newUserAdded: 'Nouvel utilisateur ajouté avec succès',
  userDetailsUpdated: "Détails de l'utilisateur mis à jour avec succès",
  yourGroups: 'Vos Groupes',
  noGroups: 'Aucun groupe',
  addUser: 'Ajouter Utilisateur',
  selectFilter: 'Sélectionner un filtre',
  applyFilter: 'Appliquer le filtre',
  clearFilter: 'Effacer le filtre',
};
