import { sendMessage } from 'store/messages';
import { clearCustomersState } from 'store/customers';
import { clearCartState } from 'store/cart';
import { useNavigate } from 'react-router';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { FC, useEffect } from 'react';
import { ValidatedType } from 'pages/Validated/Validated.types';
import env from 'config';
import ValidatedHeader from 'components/validated/ValidatedHeader';
import { Layout } from 'antd';
import { clearOrderHistory } from '../../../store/ordersHistory';
import { getOrderPath, OrderRoute } from '../../../constants/routes';
import { ValidatedOrder, ValidatedQuote } from './components';
import styles from './ValidatedContainer.module.scss';

type ValidatedContainerProps = {
  type: `${ValidatedType}`;
  cartId?: string;
  frontCartUuid?: string;
  frontCartId?: number;
};

const ValidatedContainer: FC<ValidatedContainerProps> = (props: ValidatedContainerProps) => {
  const { type, cartId, frontCartUuid, frontCartId } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearCartState());
    dispatch(clearCustomersState());
    dispatch(clearOrderHistory());
  }, [dispatch]);

  const onClickLogoutNavigate = async (): Promise<void> => {
    if (!cartId) {
      dispatch(
        sendMessage({
          message: t('common.unableToViewTheQuote'),
          type: 'error',
        }),
      );
      return;
    }

    return navigate(getOrderPath(OrderRoute.PRODUCT, cartId));
  };

  const onGotoNewFrontCart = (): void => {
    window.open(env('frontCartUrl') + (frontCartUuid ?? ''));
  };

  const onGotoBOFrontCart = (): void => {
    window.open(env('backofficeUrl') + '/cart/' + (frontCartId ?? ''));
  };

  if (type === 'quote') {
    return (
      <Layout.Content className={styles.container}>
        <ValidatedQuote
          onClickLogout={onClickLogoutNavigate}
          onGotoNewFrontCart={onGotoNewFrontCart}
          onGotoBOFrontCart={onGotoBOFrontCart}
        />
      </Layout.Content>
    );
  }

  return (
    <Layout className={styles.layout}>
      <ValidatedHeader />
      <Layout.Content className={styles.container}>
        <ValidatedOrder onCreateNewOrder={() => navigate(getOrderPath(OrderRoute.CUSTOMER))} />
      </Layout.Content>
    </Layout>
  );
};

export default ValidatedContainer;
