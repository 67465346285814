import { renderOptions } from 'utils';
import { IParsedProductItem } from 'store/products/types';
import { useTranslation as useCommonTranslation } from 'react-i18next';
import { FC, memo, useMemo } from 'react';
import { useTranslation } from 'hooks';
import env from 'config';
import { config } from 'config';
import { Popover, TagsList, Text } from 'components/shared';
import { Badge, Button, Tooltip } from 'antd';
import {
  InfoCircleOutlined,
  RedoOutlined,
  SelectOutlined,
  ShoppingCartOutlined,
  SwapOutlined,
} from '@ant-design/icons';
import stylesOnline from '../../ProductTable.module.scss';
import FlagList from '../../../../shared/FlagList';
import { ProductReplacement } from '../../../../../types/product';
import styles from './styles.module.scss';

const ProductInfo: FC<IParsedProductItem> = memo((product: IParsedProductItem) => {
  const trans = useTranslation('Cart');
  const { t } = useCommonTranslation();

  const {
    offerId,
    tags,
    name,
    options,
    replacement,
    publicComment,
    privateComment,
    productId,
    purchasableOffline,
    online,
    flags,
    productTags,
    metadata,
    campaign,
  } = product;

  const getReplacementIcon = () => {
    switch (replacement?.type) {
      case ProductReplacement.SUBSTITUTE:
        return <RedoOutlined className={styles.esPadding} />;
      case ProductReplacement.EQUIVALENT:
        return <SwapOutlined className={styles.esPadding} />;
      default:
        return '';
    }
  };

  const Info = () => {
    if (typeof replacement?.type === 'undefined' || (publicComment?.length === 0 && privateComment?.length === 0)) {
      return <span className={styles.emptyInfoBox}>&nbsp;</span>;
    }

    const Content = () => {
      return (
        <div>
          <div className={styles.commentRow}>
            <Text className={styles.subtitle}>{trans('Public')}</Text>
            <Text>{publicComment}</Text>
          </div>
          <div className={styles.commentRow}>
            <Text className={styles.subtitle}>{trans('Private')}</Text>
            <Text>{privateComment}</Text>
          </div>
        </div>
      );
    };

    return (
      <span className={styles.infoBox}>
        <Popover title={trans('Comments')} content={Content} placement="topLeft" overlayClassName={styles.popover}>
          <InfoCircleOutlined className={styles.infoIcon} />
        </Popover>
      </span>
    );
  };

  const createUrl = (): string => {
    return `${config.shopUrl}/_/p${productId}`;
  };

  const Options = () => {
    const content = renderOptions(options);
    if (!content.length) {
      return null;
    }
    return <Text className={styles.conditioning}>{content}</Text>;
  };

  const Online = useMemo(() => {
    return (
      <Text type="subtitle-thin">
        <ShoppingCartOutlined
          size={24}
          className={
            online
              ? stylesOnline.shoppingCartGreen
              : purchasableOffline
              ? stylesOnline.shoppingCartBlue
              : stylesOnline.shoppingCartGrey
          }
        />
      </Text>
    );
  }, [purchasableOffline, online]);

  return (
    <div className={typeof replacement?.type !== 'undefined' ? styles.esProduct : styles.container}>
      <span className={styles.productInfo}>
        {<Info />}
        {getReplacementIcon()}
        {metadata?.business_note && (
          <Tooltip overlay={<div style={{ whiteSpace: 'pre-wrap' }}>{metadata.business_note}</div>}>
            <>{name}</>
          </Tooltip>
        )}
        {!metadata?.business_note && <>{name}</>}

        <Button type="link" className={styles.buttonLink} href={createUrl()} target="_blank">
          <SelectOutlined />
        </Button>
        <FlagList className={styles.flags} flags={flags}  />
      </span>
      <Options />
      <div className={`${styles.list}`}>
        {productTags?.map((productTag, index) => (
          <Badge count={productTag.label} color={productTag.color ?? 'purple'} key={productTag.label + '-' + index} size={'small'} />
        ))}
      </div>
      {env('enableShowCampaign') && campaign && (
        <Text>
          #{t('common.campaign')}: {campaign}
        </Text>
      )}
      {(offerId || tags?.length) && (
        <div className={styles.information}>
          {offerId && (
            <Text className={styles.information__id}>
              #{offerId} {Online}
            </Text>
          )}
          <TagsList className={styles.flags} tags={tags} />
        </div>
      )}
    </div>
  );
});

export default ProductInfo;
