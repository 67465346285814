export const cart_en = {
  cartAttachmentUploadSuccess: '({{count}}) files has been uploaded.',
  noShippingMethods: 'No shipping methods',
  qtyEditableCheckboxLabel: 'Quantity editable',
  qtyEditableCheckboxTooltip:
    'The customer will be able to edit the quantity before validating (within the limit of stock).',
  removableCheckboxLabel: 'Removable',
  removableCheckboxTooltip: 'The customer will be able to delete the item before validating the quote.',
  replaceOffer: 'Replace the current offer for the product',
  replaceOfferAddToCart: 'Add product to cart',
  replace: 'Replace',
  articleInclusive: 'The customer will not see the item if it is excluded but will remain present in the quote',
  uploadMore: 'Upload more files',
  cartAttachments: 'Attachments',
  discountTooltip: 'Apply discount',
  commentTooltip: 'Add a comment to the product',
  warehouseStockTooltip: 'Warehouse stock',
  deleteTooltip: 'Delete product',
  checkOfferErrorMessageNotFound: 'This offer is not available any more.',
  checkOfferErrorMessageNoStock: '{{requiredStock}} (required stock) > {{availableStock}} (available stock)',
  checkOfferErrorMessagePriceUpdated: 'Offer price has been updated from {{currentOfferPrice}} to {{newOfferPrice}}.',
  checkOfferErrorMessageMargin: 'Margin can not be negative and the price has to be > 0.',
  OFFER_PRICE_UPDATED: 'Price updated',
  OFFER_NO_STOCK: 'Stock missing',
  NO_OFFER_FOUND: 'Offer has expired',
  OFFER_MARGIN_ISSUE: 'Margin issue',
  cartItemErrorTableTitle: 'Rows below may cause issues...',
  historicalProductSearchTableHeaderProduct: 'Product / Code',
  historicalProductSearchTableHeaderQuantity: 'Quantity',
  historicalProductSearchTableHeaderPrice: 'Unit Price',
  historicalProductSearchTableHeaderDocumentNumber: 'Document Number',
  historicalProductSearchTableHeaderDate: 'Date',
};
