import { createTranslation } from 'utils/translation.utils';
import { isSavedCartGroupCustomer, isSavedCartSingleCustomer } from 'utils/savedCart.utils';
import {
  getParsedCartShippingItems,
  getShippingAddressById,
  ShippingAddressesCustomersProps,
} from 'utils/delivery.utils';
import { INormalizedList } from 'types';
import {
  FetchSavedCartGroupCustomer,
  FetchSavedCartSingleCustomer,
  RestoreSavedCartCustomersGroupState,
  RestoreSavedCartCustomersSingleState,
  RestoreSavedCartResponse,
  RestoreSavedCartState,
  RestoreSavedCartStateResponse,
} from 'store/savedCart/types';
import { deliveryInitialState } from 'store/delivery/state';
import { categorizeShippingItemsNormalizer } from 'store/delivery/normalizers';
import {
  fetchCategorizeShippingItemsSavedCartAdapter,
  fetchCategorizeShippingItemsAdapter,
  fetchShippingAddressesSavedCartAdapter,
  fetchShippingAddressesAdapter,
} from 'store/delivery/adapters';
import { INormalizedGroupCustomersList } from 'store/customers/types';
import {
  fetchCustomersNormalizer,
  fetchGroupCustomersNormalizer,
  setSelectedGroupCustomerNormalizer,
} from 'store/customers/normalizers';
import { cookie, ID_TOKEN } from 'cookies';

const trans = createTranslation('SavedCart');

export const restoreCustomersNormalizer = (payload: RestoreSavedCartResponse): RestoreSavedCartState['customers'] => {
  const { savedCart, data } = payload;
  const {
    customers: { isGroup },
  } = savedCart;
  const { customers: savedCartCustomers } = savedCart;

  if (isSavedCartGroupCustomer(savedCartCustomers)) {
    const { groupCustomers } = data as FetchSavedCartGroupCustomer;
    const { customers: abandonedCustomers, groupAddress, groupTags } = fetchGroupCustomersNormalizer(groupCustomers);
    const selectedGroupCustomersIds = savedCartCustomers.selectedGroupCustomers.map((item) => item.id);
    const { selectedGroupId } = savedCartCustomers;

    const selectedGroupCustomers = abandonedCustomers
      .filter((customer) => selectedGroupCustomersIds.includes(customer.id) && customer.groupId === selectedGroupId)
      .reduce<INormalizedGroupCustomersList>((acc, customer) => {
        const { key, value } = setSelectedGroupCustomerNormalizer(customer);
        acc[key] = value;
        return acc;
      }, {});

    const selectedSearchedGroupCustomer = abandonedCustomers.find(
      (customer) => customer.id === savedCartCustomers?.selectedGroupCustomers?.find((c) => c.isCustomerLead)?.id,
    );

    return {
      isGroup,
      customers: abandonedCustomers,
      groupAddress,
      groupTags,
      selectedSearchedGroupCustomer,
      selectedGroupCustomers,
      selectedGroupId,
    } as RestoreSavedCartCustomersGroupState;
  }

  const { customers: fetchedCustomers } = data as FetchSavedCartSingleCustomer;
  const { customers } = fetchCustomersNormalizer(fetchedCustomers);
  const {
    selectedCustomer,
    selectedSearchedCustomer: { id: customerId, firstName, lastName },
  } = savedCartCustomers;

  if (!Object.prototype.hasOwnProperty.call(customers, customerId)) {
    throw createError(trans(`The customer "%s" does not longer exists.`, `${firstName} ${lastName}`));
  }

  const selectedSearchedCustomer = customers[customerId];

  return {
    isGroup,
    customers,
    selectedCustomer,
    selectedSearchedCustomer,
  } as RestoreSavedCartCustomersSingleState;
};

export const restoreDeliveryNormalizer = async (
  payload: RestoreSavedCartResponse,
  {
    customers,
    products,
  }: {
    customers: RestoreSavedCartState['customers'];
    products: RestoreSavedCartState['products'];
  },
): Promise<{
  delivery: RestoreSavedCartState['delivery'];
  deliveryErrors: RestoreSavedCartStateResponse['deliveryErrors'];
}> =>
{
  const state: RestoreSavedCartState['delivery'] = {};
  const deliveryErrors: RestoreSavedCartStateResponse['deliveryErrors'] = [];

  const { savedCart } = payload;
  const { delivery, customers: savedCartCustomers } = savedCart;

  const getSavedCartUserId = () => {
    if (isSavedCartGroupCustomer(savedCartCustomers)) return savedCartCustomers.selectedSearchedGroupCustomer.userId;
    return savedCartCustomers.selectedSearchedCustomer.userId;
  };

  const getSavedCartCustomerProps = () => {
    if (isSavedCartGroupCustomer(savedCartCustomers))
      return {
        isGroup: true,
        selectedGroupId: (customers as RestoreSavedCartCustomersGroupState).selectedGroupId,
        groupAddress: (customers as RestoreSavedCartCustomersGroupState).groupAddress,
      };
    return { isGroup: false };
  };

  if (!delivery) {
    return {
      delivery: state,
      deliveryErrors,
    };
  }

  const {
    shippingAddress: savedCartShippingAddress,
    categoryPointList: abandoedCartCategoryPointList,
    shippingItems: savedCartShippingItems,
  } = delivery;

  const { postalCodes: town } = products;
  const { products: cartProducts, users } = cart;

  try {
    if (savedCartShippingAddress) {
      const token = cookie.get(ID_TOKEN);

      const { isGroup } = savedCartCustomers;

      const userId = getSavedCartUserId();

      const shippingAddresses = await fetchShippingAddressesAdapter(
        fetchShippingAddressesSavedCartAdapter({
          userId,
          token,
        }),
      );

      const customerProps: ShippingAddressesCustomersProps = getSavedCartCustomerProps();

      const shippingAddress = getShippingAddressById(savedCartShippingAddress.id, {
        customers: customerProps,
        town,
        shippingAddresses,
      });

      const shippingItems = getParsedCartShippingItems(cartProducts.filter((i) => !i.isPlainProduct)).map((item) => {
        const savedCartShippingItem = savedCartShippingItems.find((sItem) => sItem.offerId === item.offerId);
        return {
          ...item,
          shippingMethodId: savedCartShippingItem && savedCartShippingItem.shippingMethodId,
        };
      });

      Object.assign<RestoreSavedCartState['delivery'], RestoreSavedCartState['delivery']>(state, {
        shippingAddress,
        shippingAddresses,
        shippingItems,
      });

      if (shippingAddress) {
        const getCustomerId = (): number | undefined => {
          if (isSavedCartSingleCustomer(savedCartCustomers)) {
            return (customers as RestoreSavedCartCustomersSingleState).selectedCustomer;
          }
        };

        const response = await fetchCategorizeShippingItemsAdapter(
          cartProducts,
          fetchCategorizeShippingItemsSavedCartAdapter({
            shippingItems,
            shippingAddress,
            town,
            customerId: getCustomerId(),
            isGroup,
            users,
          }),
        );

        const {
          shippingItems: shippingItemsNormalized,
          shippingFees,
          shippingCategories,
          sortedShippingCategories,
          categoryPointList,
        } = categorizeShippingItemsNormalizer(response, deliveryInitialState);

        Object.assign<RestoreSavedCartState['delivery'], RestoreSavedCartState['delivery']>(state, {
          shippingAddress,
          shippingAddresses,
          shippingItems: shippingItemsNormalized,
          shippingFees,
          shippingCategories,
          sortedShippingCategories,
        });

        if (abandoedCartCategoryPointList.length > 0) {
          state.categoryPointList = abandoedCartCategoryPointList.reduce<INormalizedList<string>>((acc, item) => {
            acc[Number(item.shippingMethodId)] = item.pointListId;
            return acc;
          }, {});
        }

        if (categoryPointList) {
          Object.assign<RestoreSavedCartState['delivery'], RestoreSavedCartState['delivery']>(state, {
            categoryPointList,
          });
        }
      }
    }
  } catch (err) {
    deliveryErrors.push(err as Error);
  }

  return {
    delivery: state,
    deliveryErrors,
  };
};
