import { percentageParser, priceParser } from 'utils';
import { selectIsCustomerMode } from 'store/products';
import {
  getShippingAddresses,
  selectDeliveryShippingItems,
  selectParsedCartShippingItems,
  setShippingItems,
} from 'store/delivery';
import { selectIsGroup, selectSelectedCustomerObject } from 'store/customers';
import {
  selectAllCartTaxes,
  selectCartPricesTotal,
  selectCartPricesTotalWithTaxes,
  selectCartProductError,
  selectCartProductNotifications,
  selectCartProducts,
  selectCartStatus,
  selectIsAnyProductSelected,
} from 'store/cart';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { FC } from 'react';
import { useStepper } from 'hooks';
import { useToken } from 'cookies';
import { Button, EcoCheckbox, TextKeyValue } from 'components/shared';
import { Space, Tooltip } from 'antd';
import { SavedCartStatusEnum } from '../../../store/savedCart/types';
import styles from './ProductSummary.module.scss';

const ProductSummary: FC = () => {
  const { goNextStep } = useStepper();
  const cartStatus = useSelector(selectCartStatus);
  const cartError = useSelector(selectCartProductError);
  const parsedCartItems = useSelector(selectParsedCartShippingItems);
  const shippingItems = useSelector(selectDeliveryShippingItems);
  const isCartEmpty = useSelector(selectIsAnyProductSelected);
  const totalPrice = useSelector(selectCartPricesTotal);
  const taxesTotalPrice = useSelector(selectCartPricesTotalWithTaxes);
  const { vatEnum, rpdSum, cvoSum, supplierSum } = useSelector(selectAllCartTaxes);
  const selectedCustomerObject = useSelector(selectSelectedCustomerObject);
  const cartItems = useSelector(selectCartProducts);
  const isGroup = useSelector(selectIsGroup);
  const { t } = useTranslation();
  const marginAmount = totalPrice - rpdSum - cvoSum - supplierSum;
  const marginPercentage = (marginAmount / (totalPrice - rpdSum - cvoSum)) * 100;
  const productNotifications = useSelector(selectCartProductNotifications);

  const isAllQuantityUsed = cartItems.find((cartItem) => {
    if (cartItem.truckSplitInfo) {
      return Object.values(cartItem.truckSplitInfo).some((truckInfo) => truckInfo.totalTruckAvailable > 0);
    }

    return cartItem.availableSelectedQuantity > 0;
  });

  const dispatch = useDispatch();
  const { token } = useToken();
  const isCustomerMode = useSelector(selectIsCustomerMode);

  const onButtonSubmit = async () => {
    dispatch(
      setShippingItems(
        parsedCartItems.map((i) => {
          const existingItem = shippingItems.find((si) => si.offerId === i.offerId);

          return {
            ...i,
            shippingMethodId: existingItem?.shippingMethodId,
          };
        }),
      ),
    );

    if (selectedCustomerObject?.userId) {
      await dispatch(getShippingAddresses({ userId: selectedCustomerObject.userId, token }));
    }

    goNextStep();
  };

  const nextStepButtonDisabled = cartStatus === SavedCartStatusEnum.CONVERTED || !isCartEmpty || (isGroup && !!isAllQuantityUsed)  || cartError || Object.keys(productNotifications).length > 0;

  return (
    <div className={styles.content}>
      <TextKeyValue label={t('common.netTotal') as string} size="large" value={priceParser(totalPrice - cvoSum)} />
      <Space size={0} direction="vertical" className={styles.taxes}>
        {Object.entries(vatEnum).map(([vat, value]) => (
          <TextKeyValue
            key={vat}
            label={`${t('common.vat')} (${parseFloat(vat) * 100}%):`}
            value={priceParser(value)}
          />
        ))}
        <TextKeyValue className={styles.topSpace} label={`${t('common.totalRpd')}:`} value={priceParser(rpdSum)} />
        <TextKeyValue className={styles.bottomSpace} label={`${t('common.totalCvo')}:`} value={priceParser(cvoSum)} />
        {supplierSum > 0 && isCustomerMode && (
          <TextKeyValue
            className={styles.bottomSpace}
            label={`${t('common.margin')}:`}
            value={`${priceParser(marginAmount, 100)} (${percentageParser(marginPercentage)})`}
          />
        )}
      </Space>
      <TextKeyValue label={`${t('common.totalPrice')}:`} size="large" value={priceParser(taxesTotalPrice)} />
      <EcoCheckbox />
      <Tooltip
        title={isGroup && !!isAllQuantityUsed ? t('errors.invalidQuantitySelected') : ''}
        className={styles.tooltiptext}
        placement="left"
      >
        <Button
          disabled={nextStepButtonDisabled}
          onClick={onButtonSubmit}
          size="large"
          className={styles.button}
          type="primary"
        >
          {t('common.selectShippingMethods')}
        </Button>{' '}
      </Tooltip>
    </div>
  );
};

export default ProductSummary;
