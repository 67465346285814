import { ICustomer, ICustomerGroup, ISearchedCustomer } from 'types';
import {
  CustomerList,
  GroupWhitelist,
  INormalizedCustomersList,
  INormalizedGroupTagsList,
  Whitelist,
  WhitelistByAtcCode,
} from 'store/customers/types';
import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../rootReducer';
import { IGroupAddress } from '../../types/groups';

export const selectIsGroup = (state: RootState): boolean => state.customers.isGroup;

export const selectAllCustomers = (state: RootState): Array<ICustomer> =>
  Object.values(state.customers.customers).sort((a, b) => a.sortIndex - b.sortIndex);

export const selectWhitelistCustomers = (state: RootState): Array<ICustomer> => {
  const customers: Array<ICustomer> = [];
  Object.values(state.customers.customers).forEach((customer) => {
    customers.push(customer);
  });
  return customers.sort((a, b) => a.sortIndex - b.sortIndex);
};

export const selectCustomersList = (state: RootState): INormalizedCustomersList => state.customers.customers;

export const selectSelectedCustomer = (state: RootState): number | undefined => state.customers.selectedCustomer;

export const selectSelectedGroupCustomers = (state: RootState): Array<ICustomerGroup> =>
  Object.values(state.customers.selectedGroupCustomers);

export const selectGroupAddressBySelectedGroup = (state: RootState): Array<IGroupAddress> | null =>
  state.customers?.selectedGroupId ? state.customers.groupAddress[state.customers.selectedGroupId] : null;

export const selectSelectedCustomerObject = (state: RootState): ICustomer | undefined =>
  state.customers.selectedCustomer ? state.customers.customers[state.customers.selectedCustomer] : undefined;

export const selectSelectedSearchedCustomer = (state: RootState): ISearchedCustomer | undefined => state.customers.selectedSearchedCustomer ?? undefined;

export const selectedUserIdForShippingAddress = (state: RootState): number | undefined => {
  const isGroup = state.customers.isGroup;
  if (isGroup) {
    return Object.values(state.customers.selectedGroupCustomers).find((group) => group.userId === group.leaderId)
      ?.userId;
  }

  if (state.customers.selectedCustomer) {
    return state.customers.customers?.[state.customers.selectedCustomer]?.userId;
  }
};

export const selectGroupTags = (state: RootState): INormalizedGroupTagsList => {
  return state.customers.groupTags;
};

export const selectSelectedGroupId = (state: RootState): number | undefined => state.customers.selectedGroupId;

export const selectTags = createSelector(
  selectIsGroup,
  selectGroupTags,
  selectSelectedGroupId,
  selectSelectedCustomer,
  selectCustomersList,
  (isGroup, groupTags, selectedGroupId, selectedCustomer, customers): Array<string> | undefined => {
    let tags: Array<string> | undefined;

    if (!isGroup) {
      tags = customers[selectedCustomer!]?.tags;
    } else {
      tags = groupTags[selectedGroupId!];
    }

    return tags;
  },
);

export const selectTcTags = (state: RootState) => {
  return [...state.cart.restoredUserTags, ...state.user.tags];
};

export const selectGroupTagsBySelectedGroup = (state: RootState): Array<string> => {
  return state.customers.selectedGroupId ? state.customers.groupTags[state.customers.selectedGroupId] : [];
};

export const selectSelectedGroupsCustomerIds = (state: RootState): Array<number> =>
  Object.values(state.customers.selectedGroupCustomers).map((customer) => customer.id);

export const selectSelectedGroupsCustomerIdentifiers = (
  state: RootState,
): Array<{ id: number; customerIdentifier: string }> =>
  Object.values(state.customers.selectedGroupCustomers).map((customer) => ({
    customerIdentifier: customer.customerIdentifier,
    id: customer.id,
  }));

export const selectGroupCustomerByIdentifier =
  (customerIdentifier: string) =>
  (state: RootState): ICustomer | undefined =>
    Object.values(state.customers.selectedGroupCustomers).find(
      (groupCustomer) => groupCustomer.customerIdentifier === String(customerIdentifier),
    );

export const selectWhitelist = (state: RootState): Whitelist => state.customers.whitelist;

export const selectCustomerList = (state: RootState): CustomerList => state.customers.customerList;

export const selectWhitelistByAtcCode = (state: RootState): WhitelistByAtcCode => state.customers.whitelistByAtcCode; // todo: works like this or duplicate ?

export const selectGroupWhitelist = (state: RootState): GroupWhitelist => state.customers.groupWhitelist;

export const selectCurrentGroupTags = (state: RootState): string[] =>
  state.customers.selectedGroupId ? state.customers.groupTags[state.customers.selectedGroupId] : [];
