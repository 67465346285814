import { IGroupCustomer, ISearchedGroupCustomer } from 'types/groups';
import { IAtcUser, ICustomer, ISearchedCustomer, ISimpleCustomer, IUser, PaginatedResponse } from 'types';
import { getToken } from 'cookies';
import { ApiResponse } from 'apisauce';
import { config } from '../config';
import { CURRENT_ATC_USER, CUSTOMERS_WHITELIST_AVAILABLE, WHITELIST } from 'constants/routes/apiRoutes';
import { RestServiceApi } from './api.service';

type ISearchCustomersParams = { search?: string; include?: boolean; type?: string };

type IGetCustomersWhitelistAvailableParams = {
  filter?: string;
  type?: string;
  atcCode?: string;
  $skip: number;
  $limit?: number;
};
type IGetGroupParams = { userId: number; type: string };
type IGetGroupWhitelistParams = { type: string; $skip: number; $limit?: number };
type IGetCustomersParams = IGetGroupParams & { include: boolean };
type IGetCustomersWhitelistParams = { type: string; $skip: number; $limit?: number };
type IGetCustomerParams = { customerId: number; type: string };

const atcCheckoutApi = new RestServiceApi(config.atcCheckoutBackendUrl || '');

export class CustomersServiceApi {
  async getCustomerById(customerId: number): Promise<ApiResponse<ICustomer>> {
    return atcCheckoutApi.get<IGetCustomerParams, ICustomer>(
      WHITELIST,
      { customerId, type: 'customers' },
      atcCheckoutApi.getAuthenticateHeader(getToken()),
    );
  }

  async getCustomersAvailableToWhitelist(
    filter: string,
    token: string,
    atcCode: string,
  ): Promise<ApiResponse<Array<ISearchedCustomer>>> {
    return atcCheckoutApi.get<IGetCustomersWhitelistAvailableParams, Array<ISearchedCustomer>>(
      CUSTOMERS_WHITELIST_AVAILABLE,
      {
        filter,
        type: 'customers',
        atcCode: atcCode,
        $skip: 0,
        $limit: 50,
      },
      atcCheckoutApi.getAuthenticateHeader(token),
    );
  }

  async searchCustomers(search: string, token: string): Promise<ApiResponse<Array<ISearchedCustomer>>> {
    return atcCheckoutApi.get<ISearchCustomersParams, Array<ISearchedCustomer>>(
      WHITELIST,
      { search, type: 'customers' },
      atcCheckoutApi.getAuthenticateHeader(token),
    );
  }

  async searchGroups(search: string, token: string): Promise<ApiResponse<Array<ISearchedGroupCustomer>>> {
    return atcCheckoutApi.get<ISearchCustomersParams, Array<ISearchedGroupCustomer>>(
      WHITELIST,
      { search, type: 'groups' },
      atcCheckoutApi.getAuthenticateHeader(token),
    );
  }

  async getCustomers(userId: number, token: string): Promise<ApiResponse<Array<ICustomer>>> {
    return atcCheckoutApi.get<IGetCustomersParams, Array<ICustomer>>(
      WHITELIST,
      { userId, include: true, type: 'customers' },
      atcCheckoutApi.getAuthenticateHeader(token),
    );
  }

  async getCustomersWhitelist(
    token: string,
    skip: number,
    limit: number,
    filter: string | null,
  ): Promise<ApiResponse<PaginatedResponse<ICustomer | ISimpleCustomer>>> {
    return atcCheckoutApi.get<IGetCustomersWhitelistParams, PaginatedResponse<ICustomer | ISimpleCustomer>>(
      WHITELIST,
      {
        type: 'customers',
        $skip: skip,
        ...(limit && { $limit: limit }),
        ...(filter && filter.trim().length > 0 && { filter }),
      },
      atcCheckoutApi.getAuthenticateHeader(token),
    );
  }

  async getCustomersWhitelistByAtcCode(
    token: string,
    skip: number,
    limit: number,
    atcCode: string,
  ): Promise<ApiResponse<PaginatedResponse<ICustomer | ISimpleCustomer>>> {
    return atcCheckoutApi.get<IGetCustomersWhitelistParams, PaginatedResponse<ICustomer | ISimpleCustomer>>(
      WHITELIST,
      {
        type: 'customers',
        $skip: skip,
        ...(limit && { $limit: limit }),
        ...(atcCode.trim().length > 0 && { atcCode }),
      },
      atcCheckoutApi.getAuthenticateHeader(token),
    );
  }

  async getGroupCustomersWhitelist(
    token: string,
    skip: number,
    limit: number,
    search: string | null,
  ): Promise<ApiResponse<PaginatedResponse<IGroupCustomer>>> {
    return atcCheckoutApi.get<IGetGroupWhitelistParams, PaginatedResponse<IGroupCustomer>>(
      WHITELIST,
      {
        type: 'groups',
        $skip: skip,
        ...(limit && { $limit: limit }),
        ...(search && search.trim().length > 0 && { search }),
      },
      atcCheckoutApi.getAuthenticateHeader(token),
    );
  }

  async getGroupCustomers(userId: number, token: string): Promise<ApiResponse<Array<IGroupCustomer>>> {
    return atcCheckoutApi.get<IGetGroupParams, Array<IGroupCustomer>>(
      WHITELIST,
      {
        userId,
        type: 'groups',
      },
      atcCheckoutApi.getAuthenticateHeader(token),
    );
  }

  async getAtcUser(token: string): Promise<ApiResponse<IAtcUser>> {
    return atcCheckoutApi.get<Record<string, never>, IAtcUser>(
      CURRENT_ATC_USER,
      {},
      atcCheckoutApi.getAuthenticateHeader(token),
    );
  }
  async getAtcUserByUsername(username: string): Promise<ApiResponse<IAtcUser>> {
    return atcCheckoutApi.get<Record<string, never>, IAtcUser>(
      `atc/user/${username}`,
      {},
      atcCheckoutApi.getAuthenticateHeader(getToken()),
    );
  }
  async removeFromWhitelist(
    customerIdentifier: string,
    atcCode: string,
  ): Promise<ApiResponse<PaginatedResponse<ICustomer>>> {
    return atcCheckoutApi.delete<Record<string, never>, PaginatedResponse<ICustomer>>(
      `customers/${customerIdentifier}/removeFromWhitelist/${atcCode}`,
      {},
      atcCheckoutApi.getAuthenticateHeader(getToken()),
    );
  }
  async addToWhitelist(
    customerIdentifier: string,
    atcCode: string,
  ): Promise<ApiResponse<PaginatedResponse<ICustomer>>> {
    return atcCheckoutApi.post<Record<string, never>, PaginatedResponse<ICustomer>>(
      `customers/${customerIdentifier}/addToWhitelist/${atcCode}`,
      {},
      atcCheckoutApi.getAuthenticateHeader(getToken()),
    );
  }
  async searchForAtcUser(search: string): Promise<ApiResponse<IAtcUser[]>> {
    return atcCheckoutApi.get<Record<string, never>, IAtcUser[]>(
      `atc/users/${search}`,
      {},
      atcCheckoutApi.getAuthenticateHeader(getToken()),
    );
  }
  async getUser(userId: number): Promise<ApiResponse<IUser>> {
    return atcCheckoutApi.get<Record<string, number>, IUser>(
      `users/${userId}`,
      {},
      atcCheckoutApi.getAuthenticateHeader(getToken()),
    );
  }
}

export const CustomersApiService = new CustomersServiceApi();
