import {
  deleteUser,
  fetchAllUsers,
  hydrateUserGroups,
  nextPage,
  prevPage,
  resetPaginationToken,
  selectPrevPageToken,
  selectNextPageToken,
  selectCurrentPageToken,
  fetchAllUsersInGroup,
} from 'store/users';
import { useAppDispatch, useAppSelector } from 'store/store';
import { UserResponse } from 'services';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import { isEmpty } from 'lodash';
import { Button } from 'components/shared';
import { Table as AntdTable, Popconfirm, Space } from 'antd';
import { getUsersPath } from 'constants/routes';
import styles from './Users.module.scss';
import MassAction from './MassAction';
import { Filters } from './Filters';

interface User {
  email: string;
  groups: string[];
  firstname: string;
  lastname: string;
  Username: string;
}

export const Table: FunctionComponent = () => {
  const { t } = useTranslation();
  const [filteringGroup, setFilteringGroup] = useState('');
  const [requesting, setRequesting] = useState(false);
  const dispatch = useAppDispatch();
  const usersState = useAppSelector((state) => state.users);
  const currentPageToken = useAppSelector(selectCurrentPageToken);
  const prevPageToken = useAppSelector(selectPrevPageToken);
  const nextPageToken = useAppSelector(selectNextPageToken);
  const navigate = useNavigate();
  const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);

  const deleteCb = (email: string) => {
    dispatch(deleteUser(email));
  };
  const editCb = (email: string, event: React.MouseEvent) => {
    event.preventDefault();
    navigate(`${getUsersPath()}/${email}`);
  };
  const editWhitelistCb = (email: string, event: React.MouseEvent) => {
    event.preventDefault();
    navigate(`${getUsersPath()}/${email}/whitelist`);
  };

  const fetchUsersCb = useCallback(
    (paginationToken, callback = undefined, filters = undefined) => {
      if (requesting) return;
      setSelectedRowKeys([]);
      dispatch(
        fetchAllUsers({
          limit: 60,
          paginationToken,
          ...(!isEmpty(filters) && { filter: filters }),
        }),
      )
        .then((action) => {
          const users = (action.payload as UserResponse)?.Users;
          if (!users) return;

          dispatch(hydrateUserGroups(users.map((user) => user.username)));
        })
        .finally(() => {
          setRequesting(false);
          if (callback) dispatch(callback());
        });
    },
    [dispatch, requesting],
  );

  const fetchUsersInGroupCb = useCallback(
    (paginationToken, callback = undefined, group = undefined) => {
      if (requesting) return;
      setSelectedRowKeys([]);
      dispatch(fetchAllUsersInGroup({ group, limit: 60, paginationToken }))
        .then((action) => {
          const users = (action.payload as UserResponse)?.Users;
          if (!users) return;
          dispatch(hydrateUserGroups(users.map((user) => user.username)));
        })
        .finally(() => {
          setRequesting(false);
          if (callback) dispatch(callback());
        });
    },
    [dispatch, requesting],
  );

  const fetchPrevUsersCb = useCallback(() => {
    filteringGroup
      ? fetchUsersInGroupCb(prevPageToken, prevPage, filteringGroup)
      : fetchUsersCb(prevPageToken, prevPage);
  }, [prevPageToken, fetchUsersCb, filteringGroup, fetchUsersInGroupCb]);

  const fetchNextUsersCb = useCallback(() => {
    filteringGroup
      ? fetchUsersInGroupCb(nextPageToken, nextPage, filteringGroup)
      : fetchUsersCb(nextPageToken, nextPage);
  }, [fetchUsersCb, fetchUsersInGroupCb, filteringGroup, nextPageToken]);

  const TABLE_COLUMNS: TableColumnDefinition<User>[] = [
    {
      title: t('users.email'),
      dataIndex: 'email',
      width: '20%',
    },
    {
      title: t('users.groups'),
      dataIndex: 'groups',
      width: '20%',
      render: (groups: string[]) => groups.join(', '),
    },
    { title: t('users.firstname'), dataIndex: 'firstname', width: '20%' },
    { title: t('users.lastname'), dataIndex: 'lastname', width: '20%' },
    {
      title: t('users.actions'),
      dataIndex: undefined,
      width: '20%',
      render: (record) => (
        <Space size="middle">
          <a href="#" onClick={editCb.bind(null, record.Username)}>
            {t('users.edit')}
          </a>
          <Popconfirm
            title={t('users.confirmDelete')}
            onConfirm={deleteCb.bind(null, record.Username)}
            okText="Yes"
            cancelText="No"
          >
            <a href="#">{t('users.delete')}</a>
          </Popconfirm>
          <a href="#" onClick={editWhitelistCb.bind(null, record.Username)}>
            {t('users.whitelist')}
          </a>
        </Space>
      ),
    },
  ];

  const items = useMemo(() => {
    if (usersState.isLoading || usersState.error) return [];

    return usersState.users.map((user) => ({
      email: user.username,
      groups: user.Groups || [],
      firstname: user.firstname,
      lastname: user.lastname,
      Username: user.Username,
    }));
  }, [usersState.error, usersState.isLoading, usersState.users]);

  const filtersChangeCb = useCallback(
    (currentFilters) => {
      dispatch(resetPaginationToken());
      if (currentFilters?.groups) {
        setFilteringGroup(currentFilters.groups);
        return fetchUsersInGroupCb('', undefined, currentFilters.groups);
      }

      return fetchUsersCb('', undefined, currentFilters);
    },
    [dispatch, fetchUsersCb, fetchUsersInGroupCb],
  );

  const onFinishedAction = useCallback(() => {
    fetchUsersCb('');
    dispatch(resetPaginationToken());
  }, [dispatch, fetchUsersCb]);

  useEffect(() => {
    fetchUsersCb('');
    return () => {
      dispatch(resetPaginationToken());
    };
  }, [dispatch, fetchUsersCb]);

  const hasPrevPage = currentPageToken !== '';
  const hasNextPage = usersState.paginationTokens.length > 0 && nextPageToken !== '';

  return (
    <>
      <div className={styles.table_header}>
        <Filters onChange={filtersChangeCb} />
        <MassAction selectedRowKeys={selectedRowKeys} onFinishedAction={onFinishedAction} />
      </div>
      <AntdTable
        className={styles.container__table}
        rowKey={'Username'}
        columns={TABLE_COLUMNS}
        dataSource={items}
        pagination={false}
        loading={usersState.isLoading}
        rowSelection={{
          selectedRowKeys,
          onChange(rowKeys) {
            setSelectedRowKeys(rowKeys as string[]);
          },
        }}
      />
      <div className={styles.container__buttonContainer}>
        <Button className={styles.container__button} onClick={fetchPrevUsersCb} disabled={!hasPrevPage}>
          {t('users.prevPage')}
        </Button>
        <Button className={styles.container__button} onClick={fetchNextUsersCb} disabled={!hasNextPage}>
          {t('users.nextPage')}
        </Button>
      </div>
    </>
  );
};
