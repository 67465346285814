import { useIsMobile, useIsTabletSizes } from 'utils/styles.utils';
import { useAppDispatch, useAppSelector } from 'store/store';
import { selectProductsError, selectTableMinimizeState } from 'store/products';
import { sendMessage } from 'store/messages';
import { selectAtcCartNumber, selectCartStatus, selectIsAnyProductSearched, setCartAtcCartNumber } from 'store/cart';
import { useSelector } from 'react-redux';
import { FC, useEffect } from 'react';
import { useGetSettings } from 'hooks/useSettings';
import { useFetchDepartures, useFetchSuppliers } from 'hooks';
import env from 'config';
import CartLogs from 'components/shared/CartLogs';
import { CartComments } from 'components/shared';
import { CartHeader, CartTable, ProductHeader, ProductSummary, ProductTable } from 'components/product';
import Stepper from 'components/layout/Stepper';
import { Layout } from 'antd';
import { createAtcCartNumber } from '../../utils';
import { selectUserTcCode } from '../../store/user';
import { SavedCartStatusEnum } from '../../store/savedCart/types';
import { usePageBlocker } from '../../hooks/usePageBlocker';
import { useFetchDeliveryShippingAddresses } from '../../hooks/useFetchDeliveryShippingAddresses';
import ReplacementModal from '../../components/product/ReplacementModal';
import WarehouseStockModal from '../../components/product/ProductTable/components/WarehouseStockModal';
import ProductTableMulti from '../../components/product/ProductTable/ProductTableMulti';
import ProductTableCustomerQuotation from '../../components/product/ProductTable/ProductTableCustomerQuotation';
import { HistoricalProductSearchTable } from '../../components/product/HistoricalProductSearch/HistoricalProductSearchTable';
import AddProductModal from '../../components/product/AddProductModal';
import styles from './Products.module.scss';

const Products: FC = () => {
  const atcCartNumber = useAppSelector(selectAtcCartNumber);
  const tcCode = useAppSelector(selectUserTcCode);
  const dispatch = useAppDispatch();
  const isMobile = useIsMobile();
  const isAnyProductSearched = useSelector(selectIsAnyProductSearched);
  const productsError = useSelector(selectProductsError);
  const currentView = useSelector(selectTableMinimizeState);
  const cartStatus = useAppSelector(selectCartStatus);
  const isTablet = useIsTabletSizes();
  const productPage = useGetSettings('PRODUCT_PAGE');

  const { blocker, confirmationModal } = usePageBlocker();

  useEffect(() => {
    if (!atcCartNumber) {
      const generatedAtcCartNumber = createAtcCartNumber(tcCode!);
      dispatch(setCartAtcCartNumber(generatedAtcCartNumber));
    }
  }, [atcCartNumber, tcCode]);

  useFetchDeliveryShippingAddresses();
  useFetchSuppliers();
  useFetchDepartures();

  useEffect(() => {
    if (productsError) {
      dispatch(sendMessage({ message: productsError.message, type: 'error' }));
    }
  }, [productsError]);

  const container = () => {
    if (productPage === 'default') {
      return (currentView || isTablet) && isAnyProductSearched && <div className={`${styles.container__table}`}>
        <ProductTable /></div>;
    }

    if (productPage === 'historical-product-search') {
      return <div className={`${styles.container__table}`}><HistoricalProductSearchTable /></div>;
    }

    if (productPage === 'multi') {
          return <div className={`${styles.container__table}`}><ProductTableMulti /></div>;
    }

    if (productPage === 'customer-quotation') {
      return <div className={`${styles.container__table}`}><ProductTableCustomerQuotation /></div>;
    }
  }

  const hideElementWhenCartIsConverted = cartStatus === SavedCartStatusEnum.CONVERTED;
  return (
    <>
      <ProductHeader />
      <Layout.Content className={styles.container}>
        { !hideElementWhenCartIsConverted && container() }
        <div className={styles.container__cart}>
          <CartHeader />
          <div className={styles.content}>
            <div className={styles.content__table}>
              <CartTable />
            </div>
            <div className={styles.content__sum}>
              <div className={styles.comment}>
                {!!env('enableCartComments') && <CartComments />}
                <div className={styles.content__timeline}>
                  <CartLogs />
                </div>
              </div>
              <div>
                <ProductSummary />
              </div>
            </div>
          </div>
        </div>
      </Layout.Content>
      {!isMobile && <Stepper footerClassName={styles.footer} className={styles.footer__stepper} />}
      <ReplacementModal />
      <AddProductModal />
      <WarehouseStockModal />
      {blocker ? confirmationModal : null}
    </>
  );
};

export default Products;
