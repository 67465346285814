import {
  createCartProduct,
  getDefaultParsedProdcutItem,
  getProductOfferByQuantity,
  percentageParser,
  priceParser,
} from 'utils';
import { useAppSelector } from 'store/store';
import {
  selectIsCustomerMode, selectPostalCode, selectReplacementModalMode,
  selectReplacementModalProductIndex,
  selectReplacementModalQuantity,
  setReplacementModalDisplayAllOffers, setReplacementModalMode,
  setReplacementModalProductIndex,
  setReplacementModalQuantity,
  setReplacementModalSku,
  setReplacementModalVisible,
  setReplacementPrice,
} from 'store/products';
import { sendMessage } from 'store/messages';
import {
  selectIsGroup,
  selectSelectedCustomerObject,
  selectSelectedGroupCustomers,
  selectSelectedGroupsCustomerIds,
  selectSelectedGroupsCustomerIdentifiers, selectTags, selectTcTags,
} from 'store/customers';
import {
  getLastUnitPricesBySku,
  replaceProduct,
  selectCartProducts,
  selectParsedReplacementProduct,
  updateCartOffers, updateProductFields,
} from 'store/cart';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { FC, memo, useMemo, useState, useEffect, useCallback } from 'react';
import { Button } from 'components/shared';
import { Popconfirm, Tooltip } from 'antd';
import { SwapOutlined } from '@ant-design/icons';
import styles from '../../ProductTable/components/ProductAmount/styles.module.scss';
import ProductAmount from '../../ProductTable/components/ProductAmount';
import { selectUserTcCode } from '../../../../store/user';
import { IParsedProductItem, REPLACEMENT_MODAL_MODE } from '../../../../store/products/types';
import { useTheme } from '../../../../hooks/useTheme';
import env from '../../../../config';

type ReplacementProductAmountProps = {
  offerId: number;
  product: IParsedProductItem;
};

const ReplacementProductAmount: FC<ReplacementProductAmountProps> = memo((props: ReplacementProductAmountProps) => {
  const { t } = useTranslation();
  const { offerId, product } = props;
  const { quantityIncrement, price } = getDefaultParsedProdcutItem(product);
  const [_initialPrice] = useState(price);
  const { customerIdentifier, user } = useSelector(selectSelectedCustomerObject) ?? {};
  const tcCode = useSelector(selectUserTcCode);
  const quantity = useSelector(selectReplacementModalQuantity);
  const mode = useSelector(selectReplacementModalMode);
  const productIndex = useSelector(selectReplacementModalProductIndex);
  const customers = useSelector(selectSelectedGroupCustomers);
  const groupLeaderIdentifier = customers.find((c) => c.userId === c.leaderId)?.customerIdentifier;

  const parsedSelector = useMemo(() => {
    return selectParsedReplacementProduct(offerId, quantity, product);
  }, [offerId, product, quantity]);

  const parsedProduct = useAppSelector(parsedSelector);
  const cartProducts = useSelector(selectCartProducts);
  const isGroup = useSelector(selectIsGroup);
  const selectedGroupCustomerIds = useSelector(selectSelectedGroupsCustomerIds);
  const selectedGroupsCustomerIdentifiers = useSelector(selectSelectedGroupsCustomerIdentifiers);
  const dispatch = useDispatch();
  const isCustomerMode = !useSelector(selectIsCustomerMode);
  const isMarginEnabled = env('enablePriceMargin');
  const themeStyle = useTheme(styles);
  const postalCode = useAppSelector(selectPostalCode);
  const tags = useAppSelector(selectTags);
  const tcTags = useAppSelector(selectTcTags);

  useEffect(() => {
    checkProductOffer(quantity);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quantity]);

  const checkProductOffer = (qty: number) => {
    const newDiscount = getProductOfferByQuantity(qty, product);
    if (newDiscount && product.offerId) {
      dispatch(
        setReplacementPrice({
          offerId: product.offerId,
          price: newDiscount.DiscountedPrice,
        }),
      );
    }
  };

  const cartProductToReplace = useMemo(() => {
    if (productIndex !== null && productIndex > -1) {
      return cartProducts[productIndex];
    }
  }, [cartProducts, productIndex]);

  const onFinish = () => {
    dispatch(setReplacementModalSku(null));
    dispatch(setReplacementModalMode(null));
    dispatch(setReplacementModalVisible(false));
    dispatch(setReplacementModalQuantity(0));
    dispatch(setReplacementModalProductIndex(null));
    dispatch(setReplacementModalDisplayAllOffers(true));
  };

  const onReplaceProduct = (keepPrice: boolean) => {
    if (!cartProductToReplace || productIndex === null) {
      return;
    }

    if (!offerId) {
      return;
    }

    if (offerId && parsedProduct) {
      const payload = createCartProduct(
        isGroup,
        selectedGroupCustomerIds,
        selectedGroupsCustomerIdentifiers,
        parsedProduct,
        product,
        quantity,
      );

      if (customerIdentifier && tcCode) {
        dispatch(
          getLastUnitPricesBySku({
            sku: product.sku,
            customerIdentifier,
            tcCode,
          }),
        );
      } else if (groupLeaderIdentifier && tcCode) {
        dispatch(
          getLastUnitPricesBySku({
            sku: product.sku,
            customerIdentifier: groupLeaderIdentifier,
            tcCode,
          }),
        );
      }

      payload.match({
        Some: ({ cartProduct }) => {
          dispatch(
            replaceProduct({
              replacement: {
                ...cartProduct,
                offerPrice:  !keepPrice ? cartProduct.offerPrice : cartProductToReplace.offerPrice,
                forcedUnitPrice: !keepPrice ? cartProduct.offerPrice : cartProductToReplace.offerPrice,
                offerUnitPrice: cartProduct.offerPrice,
                discountOffers: [...(cartProduct.discountOffers ?? [])].sort(
                  (a, b) => b.MinimumQuantity - a.MinimumQuantity,
                ),
                publicComment: cartProductToReplace.publicComment,
              },
              productIndex,
              keepPrice,
            }),
          );

          if (keepPrice) {
            dispatch(updateProductFields({
              identifier: productIndex,
              values: {
                enforcedPrice: true,
              },
            }));
          }

          onFinish();
          return;
        },
        Error: ({ message }) => {
          dispatch(
            sendMessage({
              message,
              type: 'warning',
            }),
          );
        },
      });


      // refresh cart Offers
      dispatch(updateCartOffers({
        postCode: postalCode?.postcode as string,
        tags: [...(tags ?? []), ...tcTags],
      }));

    }
  };

  const disableButton = !offerId || cartProductToReplace?.offerId === offerId;

  const getMargin = useCallback(() => {
    if (!price?.supplierUnitPrice || price?.supplierUnitPrice === 0) {
      return t('common.notAvailable');
    }

    const marginAmount = (price.price - price.supplierUnitPrice) * quantity * (quantityIncrement ?? 1);
    const marginPercentage = (marginAmount / (price.price * quantity * (quantityIncrement ?? 1))) * 100;

    return `${priceParser(marginAmount)} / ${percentageParser(marginPercentage)}`;
  }, [price, quantity, quantityIncrement, t]);

  const margin = useMemo(() => {
    if (isCustomerMode || !isMarginEnabled || !price?.supplierUnitPrice || price.supplierUnitPrice === 0) {
      return null;
    }
    return (
      <span className={`${themeStyle('margin_price')}`}>
        {t('common.margin')}: {getMargin()}
      </span>
    );
  }, [isCustomerMode, price, getMargin, isMarginEnabled, t, themeStyle]);



  const render = () => {
    if (mode === REPLACEMENT_MODAL_MODE.MODE_CHANGE_OFFER) {
      return  <Tooltip title={t('cart.replace')}>
        <Popconfirm
          title={t('modals.confirmationPopupKeepPriceTitle')}
          placement="leftTop"
          okText={t('modals.confirmationModalAcceptButton')}
          cancelText={t('modals.confirmationModalDeclineButton')}
          onCancel={() => onReplaceProduct(false)}
          onConfirm={() => onReplaceProduct(true)}
        >
          <Button icon={<SwapOutlined />} disabled={disableButton} className={styles.button} />
        </Popconfirm>
        {margin}
      </Tooltip>;
    }

    if (mode === REPLACEMENT_MODAL_MODE.MODE_ADD_OFFER_TO_CART) {
      return <>
        <div className={styles.amount}>
          <ProductAmount offerId={offerId} product={product} productType="historical-product-search" callback={onFinish} />
        </div>
        </>
    }

  }

  return (
    <div className={`${styles.amount} ${styles.replacement}`}>
      {render()}
    </div>
  );
});

export default ReplacementProductAmount;
