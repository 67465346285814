import { unwrap } from 'utils/api.utils';
import { handleCatchError } from 'utils';
import { ErrorType, IAtcUser } from 'types';
import { CustomersApiService } from 'services/customersServiceApi';
import { AsyncThunk, PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { IFetchAtcUserByUsernameParams, IFetchAtcUserParams, IUser, IUserState } from './types';

export const USER_REDUCER_NAME = 'user';

const initialState: IUserState = {
  isLoading: false,
  error: null,
  firstName: '',
  lastName: '',
  phoneNumber: '',
  username: '',
  Username: '',
  tcCode: '',
  tags: [],
  groups: [],
  permissions: [],
};

export const fetchAtcUser: AsyncThunk<IAtcUser, IFetchAtcUserParams, { rejectValue: ErrorType }> = createAsyncThunk(
  `${USER_REDUCER_NAME}/fetchAtcUser`,
  async ({ token }: IFetchAtcUserParams, { rejectWithValue }) => {
    try {
      return unwrap(await CustomersApiService.getAtcUser(token));
    } catch (err) {
      return handleCatchError(err, rejectWithValue);
    }
  },
);

export const fetchAtcUserByUsername: AsyncThunk<IAtcUser, IFetchAtcUserByUsernameParams, { rejectValue: ErrorType }> =
  createAsyncThunk(
    `${USER_REDUCER_NAME}/fetchAtcUserByUsername`,
    async ({ username }: IFetchAtcUserByUsernameParams, { rejectWithValue }) => {
      try {
        return unwrap(await CustomersApiService.getAtcUserByUsername(username));
      } catch (err) {
        return handleCatchError(err, rejectWithValue);
      }
    },
  );

const userSlice = createSlice({
  name: USER_REDUCER_NAME,
  initialState,
  reducers: {
    clearUserState() {
      return { ...initialState };
    },
    setLoggedUser(state, { payload }: PayloadAction<string>) {
      state.username = payload;
    },
    setUser(state, { payload }: PayloadAction<IUser>) {
      state.firstName = payload.firstName || '';
      state.lastName = payload.lastName || '';
      state.phoneNumber = payload.phoneNumber || '';
      state.username = payload.username;
      state.groups = payload.groups || [];
      state.permissions = payload.permissions || [];
      state.Username = payload.sub || '';
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAtcUser.fulfilled, (state, { payload }: PayloadAction<IAtcUser>) => {
      state.tcCode = payload?.code;
      state.tags = (payload?.tags || []).reduce((acc: string[], tag) => {
        acc.push(tag.toUpperCase());
        return acc;
      }, []);
      state.isLoading = false;
      state.error = null;
    });
    builder.addCase(fetchAtcUser.pending, (state) => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(fetchAtcUser.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.tcCode = undefined;
      state.error = payload;
    });
  },
});

export const { setLoggedUser, setUser, clearUserState } = userSlice.actions;
export default userSlice.reducer;
