// eslint-disable-next-line no-shadow
import { IProductPriceProps } from '../ProductPrice';

export enum DiscountType {
  /**
   * Discount percentage applied for the whole product
   * Type: 0
   */
  PERCENTAGE = 'PERCENTAGE',
  /**
   * Discount applied only once for the whole product.
   * Type: 1
   */
  AMOUNT_ONE_SHOT = 'AMOUNT_ONE_SHOT',
  /**
   * Discount applied for each quantity
   * Type 2
   */
  AMOUNT_PER_UNIT = 'AMOUNT_PER_UNIT',
}

export const typeToDiscountMap: Record<number, DiscountType> = {
  0: DiscountType.PERCENTAGE,
  1: DiscountType.AMOUNT_ONE_SHOT,
  2: DiscountType.AMOUNT_PER_UNIT,
  3: DiscountType.AMOUNT_PER_UNIT,
};

/**
 * Discount source
 * MANUAL: Discount applied manually
 * OFFER: Discount applied based on best-offer
 */
export enum DiscountSource {
  MANUAL = 'MANUAL',
  OFFER = 'OFFER',
}

export type DiscountProps = {
  productIndex: number;
  price: IProductPriceProps;
  offerType?: number;
  discountValue?: number | undefined;
  discountType?: DiscountType;
  discountError?: DiscountError;
  flags?: string[];
  discountSource?: DiscountSource;
  enforcedPrice?: boolean;
};

// eslint-disable-next-line no-shadow
export enum DiscountError {
  HIGH = 'HIGH',
  FALSE = 'FALSE',
}
